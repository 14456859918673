import { AgGridReact } from "ag-grid-react";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { Dropdown } from "react-bootstrap";
import { UserService } from "../../../services/UserService";
import toastStore from "../../stores/ToastStore";
import Pagination, { usePaging } from "../Common/Pagination";
import { ColumnApi, GridApi, GridReadyEvent } from "ag-grid-community";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye } from "@fortawesome/free-solid-svg-icons";
import ApsServices from "../../services";
import ApsModels from "../../models";
import { RouteComponentProps } from "react-router-dom";
import commonService from "../../services/CommonService";
import { useGridFilters } from "../../stores/SystemStore";
import DateTimePickerV2 from "../Common/DateTimePickerV2";
import { ISerilogSearchDto } from "../../services/http/SerilogService";
import SerilogDialog from "./SerilogDialog";

const SECURITY_LEVELS = ["All", "Error", "Warning", "Information", "Debug"];

function Serilogs(props: RouteComponentProps<any>) {
  const [departmentId, setDepartmentId] = useState(0);
  useEffect(() => {
    const disposer = UserService.currentDepartment.subscribe((id) => {
      setDepartmentId(id);
    });

    return () => {
      disposer();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [paging, setPaging] = usePaging(1, 100);
  const pageChange = (page: number, pageSize: number) => {
    setPaging({ ...paging, page: page, pageSize: pageSize });
  };

  const [list, setList] = useState<ApsModels.ISerilogGridDto>();
  const [gridApi, setGridApi] = useState<GridApi>(null as any);
  const [gridColumnApi, setGridColumnApi] = useState<ColumnApi>(null as any);

  const gridFilters = useGridFilters("systemlogs-table", gridApi, (others) => {
    if (others) {
      //   if (others?.fromDateTime !== undefined && others?.fromDateTime !== null) {
      //     setDateFrom(moment(others?.fromDateTime).toDate());
      //   }
      //   if (others?.toDateTime !== undefined && others?.toDateTime !== null) {
      //     setDateTo(moment(others?.toDateTime).toDate());
      //   }
      if (
        others?.severityLevel !== undefined &&
        others?.severityLevel !== null
      ) {
        setLevel(others?.severityLevel);
      }
    }
    setShowDates(true);
  });

  const [dateFrom, setDateFrom] = useState(
    moment().add(-5, "day").startOf("day").toDate()
  );
  const [dateTo, setDateTo] = useState(moment().endOf("day").toDate());
  const [showDates, setShowDates] = useState(false);
  const [level, setLevel] = useState(1);

  const onGridReady = (params: GridReadyEvent) => {
    setGridApi(params.api);
    setGridColumnApi(params.columnApi);
    gridFilters.init(params);
    toggleColSizes();
  };

  const toggleColSizes = () => {
    // if (gridApi && gridColumnApi && list) {
    //   setTimeout(() => {
    //     gridApi.sizeColumnsToFit();
    //     let allColumnIds: any[] = [];
    //     gridColumnApi.getAllColumns()?.forEach(function (column: any) {
    //       allColumnIds.push(column.colId);
    //     });
    //     gridColumnApi.autoSizeColumns(allColumnIds, false);
    //   }, 500);
    // }
  };

  const StatusCellComponent = (rowProps: {
    data: ApsModels.ISerilogGridItemDto;
  }) => {
    if (rowProps.data?.level === "Information") {
      return <span className="alert-chip alert alert-info">Information</span>;
    }
    if (rowProps.data?.level === "Error") {
      return <span className="alert-chip alert alert-danger">Error</span>;
    }
    if (rowProps.data?.level === "Warning") {
      return <span className="alert-chip alert alert-warning">Warning</span>;
    }
    return <span className="alert-chip alert alert-secondary">Debug</span>;
  };

  const ViewButtonCellComponent = (rowProps: {
    data: ApsModels.ISerilogItemDto;
  }) => {
    return (
      <button
        type="button"
        className="btn btn-sm btn-outlined text-primary"
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
          setShowDialog(rowProps.data.id);
        }}
      >
        <FontAwesomeIcon icon={faEye} />
      </button>
    );
  };

  const [gridState, setGridState] = useState({
    columnDefs: [
      {
        headerCheckboxSelection: true,
        checkboxSelection: true,
        field: "_selected",
        headerName: "",
        width: 10,
        ...commonService.agGridHelpers.checkboxColDef,
      },
      {
        field: "id",
        headerName: "ID",
      },
      {
        field: "userId",
        headerName: "User ID",
      },
      {
        field: "applicationName",
        headerName: "Application Name",
      },
      {
        field: "level",
        headerName: "Level",
        cellRenderer: "statusCellComponent",
      },
      {
        field: "timeStamp",
        headerName: "Date / Time",
        ...commonService.agGridHelpers.dateValueFormatter(
          "timeStamp",
          "Date / Time",
          "MMM DD, YYYY hh:mm A"
        ),
      },
      {
        field: "message",
        headerName: "Message",
        valueFormatter: (param: { data: any }) => {
          return `${(param.data.message || "").substring(0, 50)} ...`;
        },
      },
      {
        field: "action",
        headerName: "Action",
        cellRenderer: "viewButtonCellComponent",
        width: 100,
        ...commonService.agGridHelpers.actionColPinnedDef,
      },
    ],
    defaultColDef: {
      flex: 1,
      filter: true,
      sortable: true,
      resizable: true,
      menuTabs: ["filterMenuTab"],
    },
    frameworkComponents: {
      viewButtonCellComponent: ViewButtonCellComponent,
      statusCellComponent: StatusCellComponent,
    },
    autoGroupColumnDef: { minWidth: 200 },
    rowData: [] as ApsModels.ISerilogGridItemDto[],
    overlayLoadingTemplate:
      '<span class="ag-overlay-loading-center">Loading</span>',
    overlayNoRowsTemplate:
      '<span class="ag-overlay-loading-center">No Records Found</span>',
  });

  const [showDialog, setShowDialog] = useState(0);
  const [loading, setLoading] = useState(false);

  const getList = async () => {
    gridApi && gridApi.showLoadingOverlay();
    setLoading(true);

    const searchDto: ISerilogSearchDto = {
      fromDateTime: dateFrom
        ? moment(dateFrom).format("YYYY-MM-DDT00:00:00")
        : (null as any),
      toDateTime: dateTo
        ? moment(dateTo).format("YYYY-MM-DDT23:59:59")
        : (null as any),
      severityLevel: level,
    };

    await ApsServices.http.serilog
      .list(paging.page, paging.pageSize, searchDto)
      .then((data) => {
        setList(data);
        if (data.totalRecords === 0) {
          gridApi && gridApi.showNoRowsOverlay();
        }
        setLoading(false);
        gridFilters.saveOthers({ ...searchDto });
      })
      .catch((err) => {
        toastStore.showError("Failed Getting Logs", err);
        gridApi && gridApi.hideOverlay();
        setLoading(false);
      });
  };

  useEffect(() => {
    if (loading) {
      return;
    }
    if (list?.totalRecords) {
      gridApi && gridApi.hideOverlay();
    }
    setGridState({
      ...gridState,
      rowData: (list?.serilogGridItemDtos || []).map((row) => {
        return {
          ...row,
        };
      }),
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [list, loading]);

  useEffect(() => {
    if (gridApi && gridFilters.ready) {
      getList();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [gridApi, paging, gridFilters.ready, dateFrom, dateTo, level]);

  return (
    <>
      {!!showDialog && (
        <SerilogDialog
          onClose={(saved) => {
            setShowDialog(0);
          }}
          data={{ id: showDialog }}
        ></SerilogDialog>
      )}
      <div
        id="template-list"
        className="flex flex-1 flex-column course-records-list"
        style={{ marginBottom: "-60px", position: "relative" }}
      >
        {/* {loading && <CommonSpinner overlay={true}></CommonSpinner>} */}
        <div className="container-fluid flex-card-container">
          <div className="flex-0">
            <div className="headerControls">
              <div>
                <span className="h4 mb-0 font-size-18 text-uppercase">
                  System Logs
                </span>
              </div>
              <div></div>
            </div>
          </div>
          <div className="row h-full flex-1">
            <div className="col-12 col-lg-12 flex flex-col">
              <div className="card">
                <div className="card-body flex flex-col">
                  <div
                    className="flex flex-row mb-3"
                    style={{ flexWrap: "wrap", gap: "10px" }}
                  >
                    <div
                      className="flex-1 flex"
                      style={{
                        flexWrap: "wrap",
                        gap: "10px",
                        alignItems: "center",
                        minWidth: "300px",
                      }}
                    >
                      {/* <div>
                        <strong>Filter</strong>
                      </div> */}
                      <div>
                        <Dropdown drop="down">
                          <Dropdown.Toggle
                            variant="outline-secondary"
                            id={`ddMenu-${gridFilters.props._key}Picker`}
                            disabled={loading}
                          >
                            <span className="me-2">
                              Level: {SECURITY_LEVELS[level]}
                            </span>
                          </Dropdown.Toggle>
                          <Dropdown.Menu>
                            {SECURITY_LEVELS.map((lvl, i) => (
                              <Dropdown.Item
                                key={lvl}
                                onClick={(e) => {
                                  setLevel(i);
                                  pageChange(1, paging.pageSize);
                                }}
                                active={i === level}
                              >
                                {lvl}
                              </Dropdown.Item>
                            ))}
                          </Dropdown.Menu>
                        </Dropdown>
                      </div>
                      {showDates && (
                        <>
                          <DateTimePickerV2
                            dateOnly={true}
                            onChange={(data) => {
                              setDateFrom(data);
                              pageChange(1, paging.pageSize);
                            }}
                            data={dateFrom}
                            style={{ width: "120px" }}
                            disabled={loading}
                          />
                          <div>
                            <strong>to</strong>
                          </div>
                          <DateTimePickerV2
                            dateOnly={true}
                            onChange={(data) => {
                              setDateTo(data);
                              pageChange(1, paging.pageSize);
                            }}
                            data={dateTo}
                            style={{ width: "120px" }}
                            disabled={loading}
                          />
                        </>
                      )}
                    </div>
                    <div></div>
                  </div>

                  <div style={{ width: "100%", height: "100%" }}>
                    <div
                      id="myGrid"
                      style={{
                        height: "100%",
                        minHeight: "300px",
                      }}
                      className="ag-theme-alpine flex-1"
                    >
                      <AgGridReact
                        columnDefs={gridState.columnDefs}
                        autoGroupColumnDef={gridState.autoGroupColumnDef}
                        enableRangeSelection={true}
                        animateRows={true}
                        onGridReady={onGridReady}
                        rowSelection={"multiple"}
                        rowMultiSelectWithClick={false}
                        suppressRowDeselection={true}
                        suppressRowClickSelection={true}
                        frameworkComponents={gridState.frameworkComponents}
                        defaultColDef={gridState.defaultColDef}
                        enableCellTextSelection={true}
                        suppressCellSelection={true}
                        suppressMultiRangeSelection={true}
                        rowData={gridState.rowData || []}
                        onRowDoubleClicked={(event) => {
                          setShowDialog(event.data.id);
                        }}
                        overlayLoadingTemplate={
                          gridState.overlayLoadingTemplate
                        }
                        overlayNoRowsTemplate={gridState.overlayNoRowsTemplate}
                        {...gridFilters.props}
                      />
                    </div>
                  </div>
                  <Pagination
                    length={list?.totalRecords || 0}
                    page={paging.page}
                    pageSize={paging.pageSize}
                    pageChange={pageChange}
                    showingOfWhatLabel="Logs"
                    sizes={[10, 20, 50, 100]}
                  ></Pagination>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Serilogs;
