import ApsModels from "../../models";
import fetcher from "./Fetcher";

export interface ISerilogSearchDto {
  fromDateTime: string;
  toDateTime: string;
  severityLevel: number;
}
class SerilogService {
  list = async (page: number, pageSize: number, data: ISerilogSearchDto) => {
    const url = `/api/Serilog/List/${page}/${pageSize}`;
    return fetcher.post<ApsModels.ISerilogGridDto>(url, data);
  };
  get = async (id: number) => {
    const url = `/api/Serilog/${id}`;
    return fetcher.get<ApsModels.ISerilogItemDto>(url);
  };
}

const serilog = new SerilogService();
export default serilog;
