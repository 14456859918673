export interface IAssignAdminItemsDto {        
    userIds: number[];        
    courseIds: number[];        
    credentialIds: number[];        
    drillIds: number[];        
    onlineTrainingCourseDtos: IOnlineTrainingCourseDto[];        
    assignmentDateString: string;        
    saveIndividual: boolean;
}
export interface IAssignOwnItemsDto {        
    courseIds: number[];        
    credentialIds: number[];        
    assignmentDateString: string;
}
export interface IOnlineTrainingCourseDto {        
    credCourseId: number;        
    lmsCourseId: string;
}export interface INfirsBasicAuthorityBaseDto {        
    officerInChargeFirstName: string;        
    officerInChargeLastName: string;        
    officerInChargeAssignment: string;        
    officeInChargedEmpId: string;        
    officerInChargeDate: Date;        
    reportingOfficerFirstName: string;        
    reportingOfficerLastName: string;        
    reportingOfficerEmpId: string;        
    reportingOfficerDate: Date;        
    reportingOfficerAssignment: string;
}


export interface INfirsBasicAuthorityOutputDto extends INfirsBasicAuthorityBaseDto {        
    officerInChargeId: number;        
    reportingOfficerId: number;
}
export interface INfirsBasicAuthorityInputDto extends INfirsBasicAuthorityBaseDto {        
    reportId: number;
}export interface ICalJacTrainingTypeInputDto extends IUpdatableInputModelBaseDto {        
    name: string;
}
export interface ICalJacTrainingTypeOutputDto extends IUpdatableOutputModelBaseDto {        
    name: string;
}export interface ICompanyDrillTemplateDto {        
    id: number;        
    isEnabled: boolean;        
    departmentId: number;
}


export interface ICompanyOutputDto extends IUpdatableOutputModelBaseDto {        
    name: string;        
    departmentId: number;        
    isEnabled: boolean;
}
export interface ICompanyInputDto extends IUpdatableInputModelBaseDto {        
    name: string;        
    departmentId: number;        
    isEnabled: boolean;
}export interface ICredCourseCategoryOutputDto {        
    id: number;        
    description: string;        
    agency: string;
}
export interface ICredCourseRecordOutputDto {        
    id: number;        
    credCourseCategoryEntryId: number;        
    number: string;        
    name: string;        
    hours: number;        
    trainingTypeId: number;        
    courseDescription: string;        
    courseObjective: string;        
    providerNumber: string;        
    jacCode: string;        
    courseCharacteristicsDto: ICourseCharacteristicsDto;
}
export interface ICredCourseInsertDto {        
    name: string;        
    number: string;        
    credCourseCategoryEntryId: number;        
    hours: number;        
    trainingTypeId: number;        
    courseDescription: string;        
    courseObjective: string;        
    courseCharacteristicsDto: ICourseCharacteristicsDto;
}
export interface ICourseTypeAheadOutputDto {        
    id: number;        
    name: string;        
    number: string;        
    categoryId: number;        
    category: string;        
    agency: string;
}
export interface ICourseRecordParticipantOutputDto {        
    id: number;        
    firstName: string;        
    lastName: string;
}
export interface ICredTakenCourseInputGridFilterDto {        
    isMyReportsOnly: boolean;        
    userId: number;
}
export interface ICredTakenCourseExtractFilterDto {        
    ids: number[];
}
export interface ICredCourseCategoryEntryAddDto {        
    description: string;
}
export interface ICourseCharacteristicsDto {        
    isEmsCe: boolean;        
    isIsa: boolean;        
    isIso: boolean;        
    isJac: boolean;
}


export interface ICredTakeCourseUpdateBaseDto extends IUpdatableInputModelBaseDto {        
    credCourseId: number;        
    hours: number;        
    courseCharacteristicsDto: ICourseCharacteristicsDto;        
    takenFromDateString: string;        
    takenToDateString: string;        
    providerNumber: string;        
    jacCode: string;        
    instructor: string;        
    locationTaken: string;        
    trainingTypeId: number;        
    courseDescription: string;        
    courseObjective: string;        
    courseQualificationEnum: CourseQualificationEnum;        
    lmsEnrollmentId: string;
}
export interface ICredTakenCourseUpdateDto extends ICredTakeCourseUpdateBaseDto {        
    idOfFilesToDelete: number[];        
    fileIdsToCopy: number[];
}
export interface ICredTakenCourseAdminUpdateDto extends ICredTakenCourseUpdateDto {        
    userId: number;
}
export interface ICredTakenCourseAdminAddDto extends ICredTakeCourseUpdateBaseDto {        
    userIds: number[];        
    fileIdsToCopy: number[];
}
export interface ICredTakenCourseGridItemOutputDto extends IUpdatableOutputModelBaseDto {        
    courseName: string;        
    courseNumber: string;        
    categoryName: string;        
    hours: number;        
    courseCharacteristics: string;        
    takenFromDate: Date;        
    takenToDate: Date;        
    providerNumber: string;        
    jacCode: string;        
    userId: number;        
    employeeName: string;        
    updatedByName: string;        
    isFromLms: boolean;        
    library: string;        
    courseTakenStatusEnum: CourseTakenStatusEnum;        
    isTaken: boolean;        
    courseDescription: string;        
    courseObjective: string;        
    location: string;        
    instructor: string;        
    hasAttachment: boolean;        
    groups: string;        
    rank: string;        
    supervisor: string;        
    email: string;        
    employeeId: string;        
    drillReportEligibility: string;        
    isEmsCe: boolean;        
    isIsa: boolean;        
    isIso: boolean;        
    isJac: boolean;        
    isoTrainingType: string;        
    agencyJacId: string;        
    userJacId: string;
}
export interface ICredTakenCourseOutputDto extends IUpdatableOutputModelBaseDto {        
    credCourseCategoryEntryId: number;        
    credCourseId: number;        
    hours: number;        
    qualifyForEmsCe: boolean;        
    takenFromDate: Date;        
    takenToDate: Date;        
    providerNumber: string;        
    jacCode: string;        
    userId: number;        
    credFileDtos: ICredFileDto[];        
    employeeName: string;        
    isFromLms: boolean;        
    instructor: string;        
    locationTaken: string;        
    trainingTypeId: number;        
    courseDescription: string;        
    courseObjective: string;        
    courseCharacteristicsDto: ICourseCharacteristicsDto;        
    isTaken: boolean;        
    lmsEnrollmentId: string;        
    credNotificationTriggerDtos: ICredNotificationTriggerDto[];
}
export interface ICredTakenCourseOutputGridDto extends IGridOutputBaseDto {        
    credTakenCourseOuputDtos: ICredTakenCourseGridItemOutputDto[];
}
export interface ICourseRecordsUserTypeAheadInputDto extends ITypeAheadInputDto {        
    rankId: number;        
    shiftId: number;
}
export interface ICourseInstructorsTypeAheadDto extends ITypeAheadInputDto {
}
export interface ICourseTypeAheadDto extends ITypeAheadInputDto {        
    credLibraryTypeEnum: CredLibraryTypeEnum;
}
export interface ICourseCodeTypeAheadDto extends ICourseTypeAheadDto {        
    categoryId: number;
}
export interface ICourseNameTypeAheadDto extends ICourseTypeAheadDto {        
    categoryId: number;
}
export interface ICourseCategoryTypeAheadDto extends ICourseTypeAheadDto {
}
 

export enum CourseQualificationEnum {
    NoneOfTheAbove = 0,
    EmsCe = 1,
    InstructorQualifications = 2,
    Iso = 3
}
export enum CourseCharacteristicsEnum {
    None = 0,
    EmsCe = 1,
    Isa = 2,
    Iso = 4,
    Jac = 8
}
export enum CourseTakenStatusEnum {
    Assigned = 1,
    Completed = 2,
    Pending = 3,
    Rejected = 4,
    Validate = 5
}export interface ICredFileDto {        
    id: number;        
    fileName: string;
}


export interface ICredTrainingTypeOutputDto extends IUpdatableOutputModelBaseDto {        
    name: string;        
    isEnabled: boolean;
}
 

export enum CredLibraryTypeEnum {
    All = 0,
    Aps = 1,
    Department = 2
}
export enum CourseOrCredentialEnum {
    Course = 1,
    Credential = 2,
    CredentialDefault = 3,
    OnlineTraining = 4
}export interface ICredIndividualRequirementOutputDto {        
    id: number;        
    taken: boolean;        
    takenFromDate: Date;        
    takenToDate: Date;        
    hours: number;        
    credFileDtos: ICredFileDto[];        
    providerNumber: string;        
    qualifyForEmsCe: boolean;        
    instructor: string;        
    locationTaken: string;        
    trainingTypeId: number;        
    additionalInformation: string;
}


export interface ICredIndividualRequirementUpdateDto extends IUpdatableInputModelBaseDto {        
    takenCredentialId: number;        
    credentialRequirementEnum: CredentialRequirementEnum;        
    hours: number;        
    providerNumber: string;        
    jacCode: string;        
    takenFromDateString: string;        
    takenToDateString: string;        
    idOfFilesToDelete: number[];        
    instructor: string;        
    locationTaken: string;        
    trainingTypeId: number;        
    additionalInformation: string;        
    courseCharacteristicsDto: ICourseCharacteristicsDto;
}export interface ICredCredentialCategoryOutputDto {        
    id: number;        
    name: string;        
    credCategoryTypeEnum: CredCategoryTypeEnum;        
    libraryTypeEnum: CredLibraryTypeEnum;        
    agency: string;
}
export interface ICredEmptyCredentialOutputDto {        
    id: number;        
    code: string;        
    name: string;        
    instructions: string;        
    totalHoursNeeded: number;        
    expiresInYears: number;        
    lookupStateId: number;        
    credCredentialCategoryOutputDto: ICredCredentialCategoryOutputDto;        
    isEms: boolean;        
    credEmptyCredentialRequirementCategoryOutputDtos: ICredEmptyCredentialRequirementCategoryOutputDto[];        
    credPeopleInCredentialDtos: ICredPeopleInCredentialDto[];
}
export interface ICredPeopleInCredentialDto {        
    userId: number;        
    personName: string;
}
export interface ICredEmptyCredentialRequirementCategoryOutputDto {        
    id: number;        
    name: string;        
    active: boolean;        
    credEmptyCredentialRequirementGroupOutputDtos: ICredEmptyCredentialRequirementGroupOutputDto[];
}
export interface ICredEmptyCredentialRequirementGroupOutputDto {        
    id: number;        
    rules: string;        
    credEmptyCredentialRequirementOutputDtos: ICredEmptyCredentialRequirementOutputDto[];
}
export interface ICredEmptyCredentialRequirementOutputDto {        
    id: number;        
    name: string;        
    code: string;
}
export interface ICredTakenCredentialRequirementGroupOutputDto {        
    id: number;        
    rules: string;        
    taken: boolean;        
    credTakenCredentialRequirementOutputDtos: ICredTakenCredentialRequirementOutputDto[];
}
export interface ICredTakenCredentialRequirementCategoryOutputDto {        
    id: number;        
    name: string;        
    emsTakenRequirementOutputDtos: ICredTakenCredentialRequirementOutputDto[];        
    credTakenCredentialRequirementGroupOutputDtos: ICredTakenCredentialRequirementGroupOutputDto[];
}
export interface ICredCredentialTypeAheadOutputDto {        
    id: number;        
    name: string;        
    code: string;        
    category: string;        
    agency: string;
}
export interface ICredCredentialByCredentialGridItemOutputDto {        
    credentialName: string;        
    credentialCode: string;        
    credentialCategory: string;        
    credentialId: number;        
    numEmployees: number;        
    lastModifiedBy: string;        
    dateIssued: Date;        
    expirationDate: Date;        
    lastModified: Date;        
    percentageComplete: number;        
    numOfActive: number;        
    numOfExpired: number;        
    numOfInProgress: number;
}
export interface ICredTakenCredentialExtractFilterDto {        
    ids: number[];
}
export interface ICredCredentialByEmployeeGridItemOutputDto {        
    employeeName: string;        
    userId: number;        
    id: number;        
    status: CredStatusEnum;        
    numActiveCredentials: number;        
    numExpiredCredentials: number;        
    numInProgressCredentials: number;        
    dateOfExpiration: Date;        
    lastModified: Date;        
    lastModifiedBy: string;
}
export interface ICredCredentialByLastModifiedGridItemOutputDto {        
    employeeName: string;        
    userId: number;        
    id: number;        
    status: CredStatusEnum;        
    credentialName: string;        
    credentialCode: string;        
    credentialCategory: string;        
    credentialId: number;        
    dateIssued: Date;        
    dateOfExpiration: Date;        
    dueDate: Date;        
    lastModified: Date;        
    lastModifiedBy: string;        
    hasAttachment: boolean;        
    percentageComplete: number;        
    groups: string;        
    rank: string;        
    supervisor: string;        
    email: string;        
    employeeId: string;        
    periodOfValidity: number;        
    stateIssued: string;        
    hours: number;        
    hoursCompleted: number;        
    effectiveDate: Date;        
    library: string;
}
export interface ICredCredentialForEmployeeGridItemDto {        
    id: number;        
    credCredentialId: number;        
    credentialCode: string;        
    credentialCategoryId: number;        
    credCategoryTypeEnum: CredCategoryTypeEnum;        
    credentialName: string;        
    credentialCategory: string;        
    status: CredStatusEnum;        
    percentageComplete: number;        
    dueDate: Date;        
    dateIssued: Date;        
    expirationDate: Date;        
    isAssigned: boolean;        
    hasAttachment: boolean;
}
export interface ICredCredentialEmployeesInCredentialItemDto {        
    credentialId: number;        
    userId: number;        
    employeeName: string;        
    personnelId: string;        
    status: CredStatusEnum;        
    dateStarted: Date;        
    percentageComplete: number;        
    dateIssued: Date;        
    expirationDate: Date;        
    dueDate: Date;        
    takenCredentialId: number;        
    credCategoryTypeEnum: CredCategoryTypeEnum;        
    hasAttachment: boolean;
}
export interface ICredCredentialByCredentialSearchInputDto {        
    isMyReportsOnly: boolean;        
    userId: number;        
    credStatusEnum: CredStatusEnum;        
    byLastModified: boolean;        
    credentialId: number;
}
export interface ICredCredentialByLastModifiedSearchInputDto {        
    isMyReportsOnly: boolean;        
    userId: number;        
    credStatusEnum: CredStatusEnum;        
    credentialId: number;
}
export interface ICredCredentialByEmployeeSearchInputDto {        
    userId: number;        
    credStatusEnum: CredStatusEnum;
}
export interface ICredentialRequirementCategoryInputDto {        
    credentialRequirementCategory: CredentialRequirementCategoryEnum;        
    name: string;        
    active: boolean;        
    credentialRequirementGroupInputDtos: ICredentialRequirementGroupInputDto[];
}
export interface ICredentialAssignOutputDto {        
    credentialCategoryId: number;        
    credentialName: string;        
    credentialCode: string;        
    credentialHours: number;        
    credentialPeriodOfValidity: number;        
    stateIssuedId: number;
}
export interface ICredentialAdminAddOutputDto {        
    credentialCategoryId: number;        
    credentialName: string;        
    credentialCode: string;        
    credentialHours: number;        
    credentialPeriodOfValidity: number;        
    stateIssuedId: number;
}
export interface ICredentialRequirementGroupInputDto {        
    rules: string;        
    credentialRequirementInputDtos: ICredentialRequirementInputDto[];
}
export interface ICredentialRequirementInputDto {        
    name: string;        
    code: string;        
    active: boolean;        
    credentialRequirementEnum: CredentialRequirementEnum;
}
export interface ICredCredentialCategoryEntryAddDto {        
    name: string;        
    credCategoryType: CredCategoryTypeEnum;
}
export interface IOnlineTrainingCourseTypeAheadOutputDto {        
    id: number;        
    lmsCourseId: string;        
    name: string;        
    number: string;        
    categoryId: number;        
    category: string;        
    agency: string;
}
export interface ICredCredentialRenewInputDto {        
    takenCredentialId: number;        
    dateIssued: Date;        
    expirationDate: Date;
}


export interface ICredTakenCredentialUpdateBaseDto extends IUpdatableInputModelBaseDto {        
    credCredentialId: number;        
    lookupStateId: number;        
    isAssigned: boolean;        
    credTakenCredentialRequirementsUpdateDtos: ICredTakenCredentialRequirementsUpdateDto[];
}
export interface ICredTakenCredentialUpdateDto extends ICredTakenCredentialUpdateBaseDto {        
    idOfFilesToDelete: number[];        
    identificationNumber: string;        
    dateIssued: Date;        
    renewalDate: Date;        
    dueDate: Date;
}
export interface ICredTakenCredentialAdminUpdateDto extends ICredTakenCredentialUpdateDto {        
    userId: number;
}
export interface ICredTakenCredentialRequirementsUpdateDto extends IUpdatableInputModelBaseDto {        
    credCredentialRequirementId: number;        
    taken: boolean;
}
export interface ICredTakenCredentialOutputDto extends IUpdatableOutputModelBaseDto {        
    credCredentialId: number;        
    credentialCode: string;        
    credentialName: string;        
    departmentName: string;        
    userId: number;        
    employeeName: string;        
    personnelId: string;        
    credentialInstructions: string;        
    certificateFiles: ICredFileDto[];        
    dateIssued: Date;        
    expirationDate: Date;        
    dueDate: Date;        
    lookupStateId: number;        
    stateIssued: string;        
    credCredentialCategoryId: number;        
    credCategoryTypeEnum: CredCategoryTypeEnum;        
    category: string;        
    isAssigned: boolean;        
    hoursTaken: number;        
    hoursNeeded: number;        
    identificationNumber: string;        
    isEms: boolean;        
    percentageComplete: number;        
    status: CredStatusEnum;        
    totalHoursNeeded: number;        
    expiresInYears: number;        
    credTakenCredentialRequirementCategoryOutputDtos: ICredTakenCredentialRequirementCategoryOutputDto[];        
    credNotificationTriggerDtos: ICredNotificationTriggerDto[];
}
export interface ICredTakenCredentialRequirementOutputDto extends IUpdatableOutputModelBaseDto {        
    credCredentialRequirementId: number;        
    name: string;        
    code: string;        
    emsTaken: boolean;        
    dateCompleted: Date;        
    renewalDate: Date;        
    hours: number;        
    credFileDtos: ICredFileDto[];        
    instructor: string;        
    locationTaken: string;        
    trainingTypeId: number;        
    credentialRequirementEnum: CredentialRequirementEnum;        
    additionalInformation: string;
}
export interface ICredCredentialTypeAheadInputDto extends ITypeAheadInputDto {        
    categoryId: number;        
    libraryTypeEnum: CredLibraryTypeEnum;        
    onlyRenewal: boolean;
}
export interface ICredCredentialByCredentialGridOutputDto extends IGridOutputBaseDto {        
    credCredentialByCredentialGridItemOutputDtos: ICredCredentialByCredentialGridItemOutputDto[];
}
export interface ICredCredentialByEmployeeGridOutputDto extends IGridOutputBaseDto {        
    credCredentialByEmployeeGridItemOutputDtos: ICredCredentialByEmployeeGridItemOutputDto[];
}
export interface ICredTakenCredentialAdminAddDto extends IUpdatableInputModelBaseDto {        
    userIds: number[];        
    credCredentialId: number;        
    lookupStateId: number;        
    identificationNumber: string;        
    dateIssued: Date;        
    renewalDate: Date;        
    dueDate: Date;
}
export interface ICredCredentialByLastModifiedGridOutputDto extends IGridOutputBaseDto {        
    credCredentialByLastModifiedGridItemOutputDtos: ICredCredentialByLastModifiedGridItemOutputDto[];
}
export interface ICredCredentialForEmployeeGridDto extends IGridOutputBaseDto {        
    credCredentialForEmployeeGridItemDtos: ICredCredentialForEmployeeGridItemDto[];
}
export interface ICredCredentialEmployeesInCredentialGridDto extends IGridOutputBaseDto {        
    credentialId: number;        
    name: string;        
    state: string;        
    categoryName: string;        
    credentialCategoryId: number;        
    credCategoryTypeEnum: CredCategoryTypeEnum;        
    code: string;        
    credCredentialEmployeesInCredentialItemDtos: ICredCredentialEmployeesInCredentialItemDto[];
}
export interface ICredCredentialTemplateOutputDto extends ICredCredentialTemplateInsertDto {        
    id: number;        
    concurrencyToken: string;        
    canItBeUpdated: boolean;        
    mustStartNew: boolean;        
    credNotificationSettingDto: ICredNotificationSettingDto;        
    credEmptyCredentialRequirementCategoryOutputDtos: ICredEmptyCredentialRequirementCategoryOutputDto[];
}
export interface ICredentialCategoryTypeAheadDto extends ITypeAheadInputDto {        
    credCategoryType: CredCategoryTypeEnum;        
    credLibraryTypeEnum: CredLibraryTypeEnum;
}
export interface IOnlineTrainingCourseTypeAheadInputDto extends ITypeAheadInputDto {
}
 

export enum CredentialRequirementCategoryEnum {
    PriorCredentials = 0,
    Courses = 1,
    TaskBook = 2,
    SupportingDocumentation = 3,
    Other = 4
}
export enum CredentialRequirementEnum {
    PriorCredentials = 0,
    Courses = 1,
    TaskBook = 2,
    SupportingDocumentation = 3,
    Other = 4
}
export enum CredStatusEnum {
    Active = 0,
    InProgress = 1,
    Expired = 2,
    Archived = 3
}
export enum CredUserTypeAheadTypeEnum {
    Courses = 0,
    Credentials = 1,
    Dashboard = 2
}
export enum CredCategoryTypeEnum {
    General = 1,
    Ems = 2,
    ExistingExternalCredential = 3
}export interface ICredNotificationsUpdateQueueDto {        
    courseOrCredential: CourseOrCredentialEnum;        
    templateId: number;
}
export interface ICredNotificationsAddQueueDto {        
    courseOrCredential: CourseOrCredentialEnum;        
    templateId: number;        
    takenId: number;
}
export interface ICredNotificationsDeleteQueueDto {        
    courseOrCredential: CourseOrCredentialEnum;        
    takenId: number;
}export interface ICredNotificationSettingDto {        
    enableNotifications: boolean;        
    credNotificationDetails: ICredNotificationDetailDto[];        
    credNotificationDetailsCombined: ICredNotificationDetailDto[];
}
export interface ICredNotificationSettingOutputDto {        
    credentialName: string;        
    enableNotifications: boolean;        
    credNotificationSettingDto: ICredNotificationSettingDto;
}
export interface ICredCustomNotificationInputDto {        
    credCustomNotificationInputDetailDtos: ICredCustomNotificationInputDetailDto[];
}
export interface ICredCustomNotificationInputDetailDto {        
    templateId: number;        
    templateName: string;        
    credNotificationDetailDtos: ICredNotificationDetailDto[];
}
export interface ICredCustomNotificationBulkDisableDetailDto {        
    templateId: number;        
    templateName: string;
}
export interface ICredCustomNotificationOutputErrorsDto {        
    credCustomNotificationOutputErrorDetailDtos: ICredCustomNotificationOutputErrorDetailDto[];
}
export interface ICredCustomNotificationOutputErrorDetailDto {        
    templateId: number;        
    templateName: string;        
    errors: string[];
}
export interface ICredNotificationDetailDto {        
    detailId: string;        
    daysBeforeAssignmentDueDate: string;        
    daysAfterAssignmentDueDate: string;        
    notifyOnAssignmentDate: boolean;        
    notifyUponEnrollment: boolean;        
    notifyUponCompletion: boolean;        
    notifyAccountAdmins: boolean;        
    notifyImmediateSupervisors: boolean;        
    notifyParticipants: boolean;        
    notifyOther: boolean;        
    notifyOtherEmailAddresses: string;        
    notificationErrorAt: Date;
}
export interface ICredNotificationTriggerDto {        
    notificationDate: Date;        
    notificationSentAt: Date;        
    credNotificationDetailDto: ICredNotificationDetailDto;        
    canBeSent: boolean;        
    notificationSentList: INotificationSentListDto;        
    inError: boolean;        
    errorMessage: string;
}
export interface INotificationSentListDto {        
    notificationSentDetails: INotificationSentDetailDto[];
}
export interface INotificationSentDetailDto {        
    emailAddress: string;        
    emailBody: string;
}
export interface IDefaultCredentialNotificationDto {        
    disableNotifications: boolean;        
    daysBeforeAssignmentDueDate: string;        
    daysAfterAssignmentDueDate: string;        
    notifyOnAssignmentDate: boolean;        
    notifyUponEnrollment: boolean;        
    notifyUponCompletion: boolean;        
    notifyAccountAdmins: boolean;        
    notifyImmediateSupervisors: boolean;        
    notifyParticipants: boolean;        
    notifyOther: boolean;        
    notifyOtherEmailAddresses: string;
}export interface ICredCourseTemplateCopyDto {        
    fromCourseId: number;        
    name: string;
}
export interface ICredCredentialTemplateCopyDto {        
    fromCredentialId: number;        
    name: string;
}
export interface ICredCourseFileOutputDto {        
    fileName: string;        
    id: number;
}
export interface ICredCredentialTemplateInsertDto {        
    stateId: number;        
    credentialCategoryId: number;        
    name: string;        
    code: string;        
    totalHoursNeeded: number;        
    expiresInYears: number;        
    active: boolean;        
    canAccountAdminsValidate: boolean;        
    canImmediateSupervisorsValidate: boolean;        
    canOtherValidate: boolean;        
    otherValidatorEmailAddresses: string;        
    instructions: string;        
    credentialRequirementsCsvLines: string[];
}
export interface ICredCredentialTemplateUpdateDto {        
    stateId: number;        
    credentialCategoryId: number;        
    totalHoursNeeded: number;        
    expiresInYears: number;        
    active: boolean;        
    name: string;        
    code: string;        
    canAccountAdminsValidate: boolean;        
    canImmediateSupervisorsValidate: boolean;        
    canOtherValidate: boolean;        
    otherValidatorEmailAddresses: string;        
    notifyBeforeScheduledDate: boolean;        
    dateBeforeScheduledDateToNotify: string;        
    notifyAfterScheduledDate: boolean;        
    dateAfterScheduledDateToNotify: string;        
    notifyOnScheduledDate: boolean;        
    notifyOnCompletion: boolean;        
    notifyAccountAdmins: boolean;        
    notifyImmediateSupervisors: boolean;        
    notifyOther: boolean;        
    notifyOtherEmailAddresses: string;        
    instructions: string;        
    credentialRequirementsCsvLines: string[];
}
export interface ICredTemplateIdNameOutputDto {        
    id: number;        
    templateName: string;
}
export interface ICredTemplateGridItemDto {        
    id: number;        
    templateName: string;        
    category: string;        
    codeNumber: string;        
    hours: number;        
    periodOfValidity: number;        
    templateType: CourseOrCredentialEnum;        
    lastModified: Date;        
    lastAuthor: string;        
    active: boolean;        
    isFromLms: boolean;        
    lastUpdateUserDepartmentId: number;        
    lmsCourseId: string;
}
export interface ICredTemplateNotificationGridItemDto {        
    id: number;        
    templateName: string;        
    codeNumber: string;        
    templateType: CourseOrCredentialEnum;        
    active: boolean;        
    credNotificationSettingDto: ICredNotificationSettingDto;
}
export interface ICredTemplateListInputDto {        
    page: number;        
    pageSize: number;        
    orderBy: OrderByEnum;        
    credTemplateSort: CredTemplateSortFilterEnum;        
    credTemplateFilter: CredTemplateSortFilterEnum;        
    isFilter: boolean;        
    filter: string;        
    course: boolean;        
    credential: boolean;        
    onlineTraining: boolean;        
    lastModified: Date;        
    active: boolean;
}
export interface ICredTemplateNotificationsListInputDto {        
    page: number;        
    pageSize: number;        
    orderBy: OrderByEnum;        
    credTemplateSort: CredTemplateNotificationSortFilterEnum;        
    credTemplateFilter: CredTemplateNotificationSortFilterEnum;        
    isFilter: boolean;        
    filter: string;        
    active: boolean;
}


export interface ICredCourseTemplateInsertDto extends ICredCourseInsertDto {        
    active: boolean;        
    canAccountAdminsValidate: boolean;        
    canImmediateSupervisorsValidate: boolean;        
    canOtherValidate: boolean;        
    otherValidatorEmailAddresses: string;        
    notifyBeforeScheduledDate: boolean;        
    dateBeforeScheduledDateToNotify: string;        
    notifyAfterScheduledDate: boolean;        
    dateAfterScheduledDateToNotify: string;        
    notifyOnScheduledDate: boolean;        
    notifyOnCompletion: boolean;        
    notifyAccountAdmins: boolean;        
    notifyImmediateSupervisors: boolean;        
    notifyOther: boolean;        
    notifyOtherEmailAddresses: string;        
    providerNumber: string;        
    jacCode: string;
}
export interface ICredCourseTemplateOutputDto extends ICredCourseTemplateInsertDto {        
    id: number;        
    concurrencyToken: string;        
    isFromLms: boolean;        
    lmsCourseId: string;        
    credNotificationSettingDto: ICredNotificationSettingDto;        
    courseFileOutputDtos: ICredCourseFileOutputDto[];
}
export interface ICredCourseTemplateUpdateDto extends ICredCourseInsertDto {        
    id: number;        
    active: boolean;        
    canAccountAdminsValidate: boolean;        
    canImmediateSupervisorsValidate: boolean;        
    canOtherValidate: boolean;        
    otherValidatorEmailAddresses: string;        
    notifyBeforeScheduledDate: boolean;        
    dateBeforeScheduledDateToNotify: string;        
    notifyAfterScheduledDate: boolean;        
    dateAfterScheduledDateToNotify: string;        
    notifyOnScheduledDate: boolean;        
    notifyOnCompletion: boolean;        
    notifyAccountAdmins: boolean;        
    notifyImmediateSupervisors: boolean;        
    notifyOther: boolean;        
    notifyOtherEmailAddresses: string;        
    providerNumber: string;        
    jacCode: string;        
    idOfFilesToDelete: number[];
}
export interface ICredTemplateGridDto extends IGridOutputBaseDto {        
    credTemplateGridItemDtos: ICredTemplateGridItemDto[];
}
export interface ICredTemplateNotificationGridDto extends IGridOutputBaseDto {        
    credTemplateNotificationGridItemDtos: ICredTemplateNotificationGridItemDto[];
}
 

export enum CredTemplateSortFilterEnum {
    TemplateName = 1,
    Category = 2,
    CodeNumber = 3,
    TemplateType = 4,
    LastModified = 5,
    LastAuthor = 6,
    Active = 7,
    Hours = 8,
    PeriodOfValidity = 9
}
export enum CredTemplateNotificationSortFilterEnum {
    TemplateName = 1,
    CodeNumber = 2,
    TemplateType = 3,
    Active = 4
}export interface ICustomIframeSettingsOutputDto extends IUpdatableOutputModelBaseDto {        
    departmentId: number;        
    iframeModuleName: string;        
    iframeLink: string;
}
export interface ICustomIframeSettingsInputDto extends IUpdatableInputModelBaseDto {        
    departmentId: number;        
    iframeModuleName: string;        
    iframeLink: string;
}export interface IDailyOperationalConsoleTemplateInputDto {        
    departmentId: number;        
    layoutModel: string;
}
export interface IDailyOperationalConsoleTemplateOutputDto {        
    layoutModel: string;
}export interface ICredDashboardEmsItemDto {        
    id: number;        
    name: string;        
    hoursTaken: number;        
    hoursNeeded: number;        
    daysUntilNextRenewal: number;        
    totalValidDays: number;        
    expirationDate: Date;        
    inProgressCredentialId: number;
}
export interface ICredDashboardExpiringCredentialsDto {        
    id: number;        
    name: string;        
    expirationDate: Date;        
    daysUntilNextRenewal: number;        
    credCredentialCategoryId: number;        
    categoryName: string;        
    credCategoryTypeEnum: CredCategoryTypeEnum;        
    isCourse: boolean;        
    isAssigned: boolean;
}
export interface ICredDashboardDrillDto {        
    incompleteDrills: number;        
    drillHoursLogged: number;
}
export interface ICredDashboardAssignedDto {        
    id: number;        
    assignment: string;        
    dueDate: Date;        
    numberOfDaysToExpiration: number;        
    assignmentType: AssignmentTypeEnum;        
    canAccessAssignment: boolean;        
    lmsEnrollmentUrl: string;
}


 

export enum CredDashboardExpiringCategoriesFilterEnum {
    ThisYear = 0,
    NextYear = 1
}
export enum CredDashboardDrillTimeFilterEnum {
    ThisMonth = 0,
    LastMonth = 1
}
export enum AssignmentTypeEnum {
    Course = 1,
    Credential = 2,
    Drill = 3
}export interface IDemoDataInformationOutputDto {        
    canDisplayButton: boolean;        
    status: DemoDataStatusEnum;        
    lastUpdated: Date;
}


 

export enum DemoDataStatusEnum {
    NotLoaded = 1,
    InProgress = 2,
    Loaded = 3,
    Failed = 4
}
export enum DemoDataTypeEnum {
    Links = 2,
    OnlineTraining = 3,
    DrillRecords = 4,
    CredManagement = 5,
    DailyOperational = 7,
    Nfirs = 8,
    DataVisualization = 9,
    Personnel = 10,
    DeleteAll = 11
}export interface ICredDepartmentSettingsOutputDto extends IUpdatableOutputModelBaseDto {        
    departmentId: number;        
    emsceProviderNumber: string;
}
export interface ICredDepartmentSettingsInputDto extends IUpdatableInputModelBaseDto {        
    departmentId: number;        
    emsceProviderNumber: string;
}export interface IExtraDrillInformationDto {        
    id: number;        
    departmentId: number;        
    isaCourseId: number;        
    logDrillSubjectId: number;        
    calJacTrainingTypeId: number;        
    logDrillAreaOfStudyId: number;
}


export interface IDrillOutputDto extends IUpdatableOutputModelBaseDto {        
    name: string;        
    departmentId: number;        
    isEnabled: boolean;        
    isaCourseId: number;        
    logDrillSubjectId: number;        
    calJacTrainingTypeId: number;        
    logDrillAreaOfStudyId: number;        
    isFavorite: boolean;
}
export interface IDrillInputDto extends IUpdatableInputModelBaseDto {        
    name: string;        
    departmentId: number;        
    isEnabled: boolean;        
    isaCourseId: number;        
    logDrillSubjectId: number;        
    calJacTrainingTypeId: number;        
    logDrillAreaOfStudyId: number;
}export interface IDrillReportAssignInputDto {        
    id: number;        
    drillId: number;        
    participantIds: number[];        
    dueDateString: string;
}
export interface IDrillReportGridOutputItemDto {        
    id: number;        
    reporter: string;        
    reporterId: number;        
    drillName: string;        
    location: string;        
    date: Date;        
    endDate: Date;        
    hours: number;        
    calJac: string;        
    isaCourse: string;        
    isaEligibility: string;        
    drillStatus: DrillStatusEnum;        
    canBeChanged: boolean;        
    drillSubject: string;        
    drillAreaOfStudy: string;        
    calJacTrainingType: string;        
    company: string;        
    reporterName: string;        
    instructor: string;        
    agencyJacId: string;        
    trainingTypes: string[];        
    lastModifiedOn: Date;        
    description: string;        
    shift: string;        
    isaDetailsOfCourse: string;        
    selectedParticipants: IDrillReportParticipantOutputDto[];
}
export interface IDrillReportGridInputDto {        
    startTimeString: string;        
    endTimeString: string;        
    isMyReportsOnly: boolean;        
    page: number;        
    pageSize: number;
}
export interface IDrillReportParticipantOutputDto {        
    id: number;        
    firstName: string;        
    lastName: string;        
    departmentName: string;        
    drillReportEligibility: string;        
    groups: string;        
    rank: string;        
    employeeId: string;        
    supervisor: string;        
    email: string;        
    userJacId: string;
}
export interface IDrillReportLastCompanyNameShiftOutputDto {        
    logDrillShiftId: number;        
    companyId: number;
}
export interface IDrillReportExtractInputDto {        
    drillReportExtractInputItemDtos: IDrillReportExtractInputItemDto[];
}
export interface IDrillReportExtractInputItemDto {        
    drillReportId: number;        
    participants: number[];
}


export interface IDrillReportInputDto extends IUpdatableInputModelBaseDto {        
    isFirefighterReport: boolean;        
    startTimeString: string;        
    endTimeString: string;        
    description: string;        
    drillId: number;        
    locationId: number;        
    calJacTrainingTypeId: number;        
    logDrillShiftId: number;        
    companyId: number;        
    isaCourseId: number;        
    logDrillSubjectId: number;        
    logDrillAreaOfStudyId: number;        
    otherDrillName: string;        
    otherLocation: string;        
    otherTrainingType: string;        
    otherIsaCourse: string;        
    instructor: string;        
    instructorUserId: number;        
    participantIds: number[];        
    selectedTrainingTypeIds: number[];
}
export interface IDrillReportDraftInputDto extends IDrillReportInputDto {
}
export interface IDrillReportSubmittedInputDto extends IDrillReportInputDto {
}
export interface IDrillReportOutputDto extends IUpdatableOutputModelBaseDto {        
    isFirefighterReport: boolean;        
    hasBeenSubmitted: boolean;        
    reporterId: number;        
    duration: number;        
    startTime: Date;        
    endTime: Date;        
    startTimeString: string;        
    endTimeString: string;        
    description: string;        
    drillId: number;        
    locationId: number;        
    calJacTrainingTypeId: number;        
    logDrillShiftId: number;        
    companyId: number;        
    isaCourseId: number;        
    logDrillSubjectId: number;        
    logDrillAreaOfStudyId: number;        
    otherDrillName: string;        
    otherLocation: string;        
    otherTrainingType: string;        
    otherIsaCourse: string;        
    instructor: string;        
    canBeChanged: boolean;        
    instructorUserId: number;        
    selectedParticipants: IDrillReportParticipantOutputDto[];        
    selectedTrainingTypeIds: number[];
}
export interface IDrillReportGridDto extends IGridOutputBaseDto {        
    drillReportGridOutputItemDtos: IDrillReportGridOutputItemDto[];
}
export interface IUserDrillReportGridInputDto extends IDrillReportGridInputDto {        
    userId: number;
}
export interface IDrillReportUserTypeAheadInputDto extends ITypeAheadInputDto {
}
 

export enum DrillStatusEnum {
    Submitted = 1,
    Draft = 2,
    Assigned = 3
}export interface IDrillReportVerificationDto {        
    drillReportVerificationEnum: DrillReportVerificationEnum;        
    drillReportOverlappingDetailDtos: IDrillReportOverlappingDetailDto[];        
    drillReportExcessiveListDtos: IDrillReportExcessiveListDto[];
}
export interface IDrillReportExcessiveListDto {        
    participant: IDrillReportParticipantOutputDto;        
    drillReportVerificationReportDtos: IDrillReportVerificationReportDto[];
}
export interface IDrillReportOverlappingDetailDto {        
    participant: IDrillReportParticipantOutputDto;        
    drillReportVerificationReportDto: IDrillReportVerificationReportDto;
}
export interface IDrillReportVerificationReportDto {        
    keep: boolean;        
    drillReportId: number;        
    startTime: Date;        
    endTime: Date;
}


 

export enum DrillReportVerificationEnum {
    NoErrors = 0,
    Excessive = 1,
    Overlapping = 2
}export interface IDrillTemplateInputDto extends IUpdatableInputModelBaseDto {        
    departmentId: number;        
    isIsaEnabled: boolean;        
    isJacEnabled: boolean;        
    isCompanyEnabled: boolean;        
    isShiftEnabled: boolean;        
    isDrillEnabled: boolean;        
    isLocationEnabled: boolean;        
    isTrainingTypeEnabled: boolean;        
    isTrainingTypeMultiSelectEnabled: boolean;        
    useDurationYesNo: boolean;        
    companies: ICompanyInputDto[];        
    drills: IDrillInputDto[];        
    isaCourses: IIsaCourseInputDto[];        
    locations: ILocationInputDto[];        
    logDrillShifts: ILogDrillShiftInputDto[];        
    trainingTypes: ITrainingTypeInputDto[];        
    numDaysReportDraftCanBeKept: number;        
    numDaysSubmittedReportCanBeEdited: number;        
    numDaysToSubmitDrill: number;        
    jacAgencyId: string;
}
export interface IDrillTemplateOutputDto extends IUpdatableOutputModelBaseDto {        
    departmentId: number;        
    departmentName: string;        
    isIsaEnabled: boolean;        
    isJacEnabled: boolean;        
    isCompanyEnabled: boolean;        
    isShiftEnabled: boolean;        
    isDrillEnabled: boolean;        
    isLocationEnabled: boolean;        
    isTrainingTypeEnabled: boolean;        
    isTrainingTypeMultiSelectEnabled: boolean;        
    useDurationYesNo: boolean;        
    companies: ICompanyOutputDto[];        
    drills: IDrillOutputDto[];        
    isaCourses: IIsaCourseOutputDto[];        
    locations: ILocationOutputDto[];        
    logDrillShifts: ILogDrillShiftOutputDto[];        
    trainingTypes: ITrainingTypeOutputDto[];        
    numDaysReportDraftCanBeKept: number;        
    numDaysSubmittedReportCanBeEdited: number;        
    numDaysToSubmitDrill: number;        
    jacAgencyId: string;
}export interface IReportDisplayOutputDto {        
    requestedOn: Date;        
    processedOn: Date;        
    dynamicReportEnum: DynamicReportEnum;        
    outputFileName: string;
}
export interface IDynamicReportTemplateOutputDto {        
    id: number;        
    name: string;        
    reportType: DynamicReportEnum;        
    template: string;        
    departmentId: number;        
    userId: number;
}
export interface IDynamicReportTemplateInputDto {        
    reportType: DynamicReportEnum;        
    jsonData: string;        
    name: string;
}
export interface IDynamicReportTemplateSaveDto {        
    name: string;        
    nfirsDynamicReportRequestDto: INfirsDynamicReportRequestDto;
}


export interface IDynamicReportTemplateGridOutputDto extends IGridOutputBaseDto {        
    dynamicReportTemplateOutputDtos: IDynamicReportTemplateOutputDto[];
}export interface IErrorOutputDto {        
    fieldName: string;        
    message: string;
}
export interface IHttpErrorOutputDto {        
    errors: IErrorOutputDto[];        
    statusCode: number;
}export interface IEsriDashboardDto {        
    id: number;        
    name: string;        
    link: string;
}export interface ICredExistingCredentialInputBaseDto {        
    dateIssueString: string;        
    dateExpirationString: string;
}


export interface ICredExistingCredentialInsertDto extends ICredExistingCredentialInputBaseDto {        
    name: string;        
    identificationNumber: string;        
    userIds: number[];        
    userIdsToRemove: number[];        
    lookupStateId: number;
}
export interface ICredExistingCredentialSelfUpdateDto extends ICredExistingCredentialInputBaseDto {        
    id: number;        
    idOfFilesToDelete: number[];
}
export interface ICredExistingCredentialSelfInsertDto extends ICredExistingCredentialInputBaseDto {        
    name: string;        
    identificationNumber: string;        
    lookupStateId: number;
}
export interface ICredExistingCredentialAdminUpdateDto extends ICredExistingCredentialInputBaseDto {        
    id: number;        
    userId: number;        
    idOfFilesToDelete: number[];
}
export interface ICredExistingCredentialOutputDto extends IUpdatableOutputModelBaseDto {        
    name: string;        
    identificationNumber: string;        
    dateIssued: Date;        
    expirationDate: Date;        
    lookupStateId: number;        
    lookupStateName: string;        
    userId: string;        
    certificateFiles: ICredFileDto[];
}export interface ILookupStatesOutputDto {        
    id: number;        
    abbreviation: string;        
    description: string;
}
export interface ILookupIntDto {        
    label: string;        
    value: number;
}
export interface IGridPageSizesDto {        
    firstPage: number;        
    otherPages: number;
}export interface IBooleanOutputDto {        
    value: boolean;
}
export interface IGenericStringInputDto {        
    string: string;
}
export interface IGridOutputBaseDto {        
    totalRecords: number;
}
export interface ITypeAheadInputDto {        
    search: string;        
    recordCount: number;        
    isAnd: boolean;
}


 

export enum OrderByEnum {
    Ascending = 1,
    Descending = 2
}
export enum ActiveEnum {
    All = 0,
    Active = 1,
    Inactive = 2
}export interface IIsaCourseOutputDto extends IUpdatableOutputModelBaseDto {        
    name: string;        
    departmentId: number;        
    sortOrder: number;        
    isEnabled: boolean;
}
export interface IIsaCourseInputDto extends IUpdatableInputModelBaseDto {        
    name: string;        
    departmentId: number;        
    sortOrder: number;        
    isEnabled: boolean;        
    temporaryId: number;
}export interface IIsoDateSummaryDto {        
    userId: number;        
    hazmatHours: number;        
    hazmatRollableHours: number;        
    officerHours: number;        
    officerRollableHours: number;        
    driverHours: number;        
    driverRollableHours: number;        
    newDriverHours: number;        
    newDriverRollableHours: number;        
    facilitiesHours: number;        
    facilitiesRollableHours: number;        
    companyHours: number;        
    date: Date;
}export interface IIsoReportListItemDto {        
    id: number;        
    name: string;        
    description: string;        
    isoReportTypeEnum: IsoReportTypeEnum;
}
export interface IIsoTrainingParticipantOutputDto {        
    id: number;        
    firstName: string;        
    lastName: string;
}
export interface IIsoBaseParametersDto {        
    fromDateString: string;        
    toDateString: string;
}
export interface IIsoReportOverviewDto {        
    isoReportOverviewItemDtos: IIsoReportOverviewItemDto[];
}
export interface IIsoReportOverviewItemDto {        
    category: string;        
    isoReportCategoryEnum: IsoReportCategoryEnum;        
    isoReportOverviewItemDetailDtos: IIsoReportOverviewItemDetailDto[];
}
export interface IIsoReportOverviewItemDetailDto {        
    description: string;        
    number: number;
}
export interface IIsoReportDetailDto {        
    isoReportDetailItemDtos: IIsoReportDetailItemDto[];
}
export interface IIsoReportDetailItemDto {        
    userId: number;        
    personnelName: string;        
    personnelId: string;        
    rank: string;        
    companyTraining: number;        
    facilitiesTraining: number;        
    newDriverTraining: number;        
    driverTraining: number;        
    officerTraining: number;        
    hazmatTraining: number;        
    total: number;        
    maxCompanyTraining: number;        
    maxFacilitiesTraining: number;        
    maxNewDriverTraining: number;        
    maxDriverTraining: number;        
    maxOfficerTraining: number;        
    maxHazmatTraining: number;        
    displayNewDriverTraining: boolean;        
    displayExistingDriverTraining: boolean;        
    displayOfficeTraining: boolean;
}
export interface IIsoIndividualDetailReportDto {        
    isoIndividualDetailReportItemDtos: IIsoIndividualDetailReportItemDto[];
}
export interface IIsoIndividualDetailReportItemDto {        
    userId: number;        
    personnelName: string;        
    personnelId: string;        
    rank: string;        
    trainingCategories: string[];        
    course: string;        
    date: Date;        
    hours: number;        
    location: string;
}


export interface IIsoTrainingUserTypeAheadInputDto extends ITypeAheadInputDto {
}
export interface IIsoTrainingReportParametersDto extends IIsoBaseParametersDto {        
    users: number[];        
    isoReportCategoryEnums: number[];
}
export interface IIsoIndividualReportParametersDto extends IIsoBaseParametersDto {        
    userId: number;        
    isoReportCategoryEnums: number[];
}
 

export enum IsoReportTypeEnum {
    IsoOverviewReport = 1,
    IsoDetailReport = 2,
    NfirsDyanamicReport = 3,
    TrainingHours = 4
}
export enum IsoReportCategoryEnum {
    CompanyTraining = 1,
    FacilitiesTraining = 2,
    NewDriverTraining = 3,
    DriverTraining = 4,
    OfficerTraining = 5,
    HazmatTraining = 6,
    NoMatch = 7
}export interface ILocationDrillTemplateDto {        
    id: number;        
    isEnabled: boolean;        
    departmentId: number;
}


export interface ILocationInputDto extends IUpdatableInputModelBaseDto {        
    name: string;        
    departmentId: number;        
    isEnabled: boolean;
}
export interface ILocationOutputDto extends IUpdatableOutputModelBaseDto {        
    name: string;        
    departmentId: number;        
    isEnabled: boolean;
}export interface ILogDrillAreaOfStudyOutputDto extends IUpdatableOutputModelBaseDto {        
    name: string;        
    logDrillSubjectId: number;
}export interface ILogDrillNameFavoriteInputDto {        
    id: number;
}export interface ILogDrillShiftDrillTemplateDto {        
    id: number;        
    isEnabled: boolean;        
    departmentId: number;
}


export interface ILogDrillShiftInputDto extends IUpdatableInputModelBaseDto {        
    name: string;        
    departmentId: number;        
    isEnabled: boolean;
}
export interface ILogDrillShiftOutputDto extends IUpdatableOutputModelBaseDto {        
    name: string;        
    departmentId: number;        
    isEnabled: boolean;
}export interface ILogDrillSubjectOutputDto extends IUpdatableOutputModelBaseDto {        
    name: string;
}export interface INfirsApparatusInputDto extends IUpdatableInputModelBaseDto {        
    reportId: number;        
    apparatusId: string;        
    nfirsApparatusTypeId: number;        
    dispatchDateTime: Date;        
    arrivalDateTime: Date;        
    clearDateTime: Date;        
    nfirsApparatusUseTypeId: number;        
    numberOfPeople: number;        
    isSent: boolean;        
    nfirsPersonnelId: number;        
    nfirsActionTaken1Id: number;        
    nfirsActionTaken2Id: number;
}
export interface INfirsApparatusOutputDto extends IUpdatableOutputModelBaseDto {        
    apparatusId: string;        
    nfirsApparatusTypeId: number;        
    dispatchDateTime: Date;        
    arrivalDateTime: Date;        
    clearDateTime: Date;        
    nfirsApparatusUseTypeId: number;        
    numberOfPeople: number;        
    isSent: boolean;        
    nfirsPersonnelId: number;        
    nfirsActionTaken1Id: number;        
    nfirsActionTaken2Id: number;
}
export interface INfirsApparatusGridItemDto extends INfirsApparatusOutputDto {        
    apparatusUseType: string;
}
export interface INfirsApparatusGridOutputDto extends IGridOutputBaseDto {        
    nfirsApparatusGridItemDtos: INfirsApparatusGridItemDto[];
}
 

export enum GeneralApparatusTypeEnum {
    GroundFireSuppression = 1,
    HeavyGroundEquipment = 2,
    Aircraft = 3,
    MarineEquipment = 4,
    SupportEquipment = 5,
    MedicalAndRescueUnit = 6,
    Other = 7
}
export enum NfirsActionsTakenSubsectionEnum {
    FireControlOrExtinguishment = 1,
    SearchAndRescue = 2,
    EmsAndTransport = 3,
    HazardousCondition = 4,
    FiresRescuesAndHazardousConditions = 5,
    SystemsAndServices = 6,
    Assistance = 7,
    InformationInvestigationAndEnforcement = 8,
    FillInStandby = 9
}export interface INfirsArsonDetailInputDto extends IUpdatableInputModelBaseDto {        
    reportId: number;        
    nfirsArsonApparentGroupInvolvementEntryId: number;        
    nfirsArsonApparentGroupInvolvementEntryId2: number;        
    nfirsArsonApparentGroupInvolvementEntryId3: number;        
    nfirsArsonEntryMethodEntryId: number;        
    nfirsArsonExtentOfFireInvolvementArrivalEntryId: number;        
    nfirsArsonIncendiaryDeviceEntryId: number;        
    nfirsArsonIgnitionDelayDeviceEntryId: number;        
    nfirsArsonFuelEntryId: number;        
    initialObservationIds: number[];        
    laboratoryUsedIds: number[];        
    otherInvestigationIds: number[];        
    propertyOwnershipIds: number[];
}
export interface INfirsArsonDetailOutputDto extends IUpdatableOutputModelBaseDto {        
    nfirsArsonApparentGroupInvolvementEntryId: number;        
    nfirsArsonApparentGroupInvolvementEntryId2: number;        
    nfirsArsonApparentGroupInvolvementEntryId3: number;        
    nfirsArsonEntryMethodEntryId: number;        
    nfirsArsonExtentOfFireInvolvementArrivalEntryId: number;        
    nfirsArsonIncendiaryDeviceEntryId: number;        
    nfirsArsonIgnitionDelayDeviceEntryId: number;        
    nfirsArsonFuelEntryId: number;        
    initialObservationIds: number[];        
    laboratoryUsedIds: number[];        
    otherInvestigationIds: number[];        
    propertyOwnershipIds: number[];
}export interface INfirsArsonAgencyReferralInputDto extends IUpdatableInputModelBaseDto {        
    reportId: number;        
    isAgencyReferral: boolean;        
    agencyName: string;        
    caseNumber: string;        
    number: string;        
    prefix: string;        
    street: string;        
    streetType: string;        
    suffix: string;        
    ori: string;        
    postOfficeBox: string;        
    apt: string;        
    city: string;        
    fid: string;        
    stateId: number;        
    zipCode: string;        
    agencyPhoneNumber: string;        
    fdid: string;
}
export interface INfirsArsonAgencyReferralOutputDto extends IUpdatableOutputModelBaseDto {        
    isAgencyReferral: boolean;        
    agencyName: string;        
    caseNumber: string;        
    number: string;        
    prefix: string;        
    street: string;        
    streetType: string;        
    suffix: string;        
    ori: string;        
    postOfficeBox: string;        
    apt: string;        
    city: string;        
    fid: string;        
    stateId: number;        
    zipCode: string;        
    agencyPhoneNumber: string;        
    fdid: string;
}export interface INfirsArsonInvestigationInputDto extends IUpdatableInputModelBaseDto {        
    reportId: number;        
    nfirsArsonInvestigationStatus: NfirsArsonInvestigationStatusEnum;        
    nfirsAvailabilityOfFirstMaterialIgnitedEntryId: number;        
    nfirsArsonSuspectedMotivationFactorEntry1Id: number;        
    nfirsArsonSuspectedMotivationFactorEntry2Id: number;        
    nfirsArsonSuspectedMotivationFactorEntry3Id: number;
}
export interface INfirsArsonInvestigationOutputDto extends IUpdatableOutputModelBaseDto {        
    nfirsArsonInvestigationStatus: NfirsArsonInvestigationStatusEnum;        
    nfirsAvailabilityOfFirstMaterialIgnitedEntryId: number;        
    nfirsArsonSuspectedMotivationFactorEntry1Id: number;        
    nfirsArsonSuspectedMotivationFactorEntry2Id: number;        
    nfirsArsonSuspectedMotivationFactorEntry3Id: number;
}
 

export enum NfirsArsonInvestigationStatusEnum {
    InvestigationOpen = 1,
    InvestigationClosed = 2,
    InvestigationInactive = 3,
    ClosedWithArrest = 4,
    ClosedWithExceptionalClearance = 5
}export interface INfirsArsonJuvenileInputDto extends IUpdatableInputModelBaseDto {        
    reportId: number;        
    subjectNumber: number;        
    age: number;        
    dateOfBirth: Date;        
    gender: NfirsJuvenileMaleFemaleEnum;        
    nfirsJuvenileRaceEntryId: number;        
    ethnicity: NfirsJuvenileEthnicityEnum;        
    nfirsJuvenileFamilyTypeEntryId: number;        
    nfirsJuvenileDispositionEntryId: number;        
    riskFactorIds: number[];
}
export interface INfirsArsonJuvenileOutputDto extends IUpdatableOutputModelBaseDto {        
    subjectNumber: number;        
    age: number;        
    dateOfBirth: Date;        
    gender: NfirsJuvenileMaleFemaleEnum;        
    nfirsJuvenileRaceEntryId: number;        
    ethnicity: NfirsJuvenileEthnicityEnum;        
    nfirsJuvenileFamilyTypeEntryId: number;        
    nfirsJuvenileDispositionEntryId: number;        
    riskFactorIds: number[];
}
 

export enum NfirsJuvenileMaleFemaleEnum {
    Female = 1,
    Male = 2
}
export enum NfirsJuvenileEthnicityEnum {
    NonHispanicOrLatino = 0,
    HispanicOrLatino = 1
}export interface INfirsBasicActionOutputDto extends IUpdatableOutputModelBaseDto {        
    nfirsActionTaken1Id: number;        
    nfirsActionTaken2Id: number;        
    nfirsActionTaken3Id: number;        
    suppressionPersonnel: number;        
    suppressionApparatus: number;        
    emsPersonnel: number;        
    emsApparatus: number;        
    otherPersonnel: number;        
    otherApparatus: number;        
    fireServiceDeaths: number;        
    fireServiceInjuries: number;        
    civilianDeaths: number;        
    civilianInjuries: number;
}
export interface INfirsBasicActionInputDto extends IUpdatableInputModelBaseDto {        
    nfirsActionTaken1Id: number;        
    nfirsActionTaken2Id: number;        
    nfirsActionTaken3Id: number;        
    suppressionPersonnel: number;        
    suppressionApparatus: number;        
    emsPersonnel: number;        
    emsApparatus: number;        
    otherPersonnel: number;        
    otherApparatus: number;        
    fireServiceDeaths: number;        
    fireServiceInjuries: number;        
    civilianDeaths: number;        
    civilianInjuries: number;        
    reportId: number;
}export interface INfirsBasicAuthorityUserOutputDto {        
    personnelId: string;        
    firstName: string;        
    lastName: string;        
    group: string;
}


export interface INfirsBasicAuthorityOutputDto extends IUpdatableOutputModelBaseDto {        
    officerInChargeFirstName: string;        
    officerInChargeLastName: string;        
    officeInChargedAgencyId: string;        
    officerInChargeDate: Date;        
    reportingOfficerFirstName: string;        
    reportingOfficerLastName: string;        
    reportingOfficerAgencyId: string;        
    reportingOfficerDate: Date;        
    officerInChargeId: number;        
    reportingOfficerId: number;        
    nfirsBasicAuthorityOfficerInChargeAssignmentTypeEntryId: number;        
    nfirsBasicAuthorityReportingOfficerAssignmentTypeEntryId: number;
}
export interface INfirsBasicAuthorityInputDto extends IUpdatableInputModelBaseDto {        
    officeInChargedAgencyId: string;        
    officerInChargeDate: Date;        
    reportingOfficerAgencyId: string;        
    reportingOfficerDate: Date;        
    reportId: number;        
    nfirsBasicAuthorityOfficerInChargeAssignmentTypeEntryId: number;        
    nfirsBasicAuthorityReportingOfficerAssignmentTypeEntryId: number;
}
export interface INfirsUserTypeAheadInputDto extends ITypeAheadInputDto {
}export interface INfirsBasicDateOutputDto extends IUpdatableOutputModelBaseDto {        
    alarmDate: string;        
    alarmTime: string;        
    arrivalDate: string;        
    arrivalTime: string;        
    incidentControlledDate: string;        
    incidentControlledTime: string;        
    lastUnitClearedDate: string;        
    lastUnitClearedTime: string;        
    shift: string;        
    alarms: number;        
    district: string;
}
export interface INfirsBasicDateInputDto extends IUpdatableInputModelBaseDto {        
    alarmDate: string;        
    arrivalDate: string;        
    incidentControlledDate: string;        
    lastUnitClearedDate: string;        
    shift: string;        
    reportId: number;        
    alarms: number;        
    district: string;
}export interface INfirsBasicIncidentInputDto extends IUpdatableInputModelBaseDto {        
    exposure: number;        
    nfirsIncidentGroupId: number;        
    nfirsIncidentTypeId: number;        
    nfirsAidGivenReceivedEntryId: number;        
    nfirsFDReceivedAidEntryId: number;        
    otherFDId: string;        
    lookupStateId: number;        
    incidentNumberOfFireDeptReceivingAid: string;        
    reportId: number;
}
export interface INfirsBasicIncidentOutputDto extends IUpdatableOutputModelBaseDto {        
    caseNumber: string;        
    exposure: number;        
    eventNumber: string;        
    nfirsIncidentGroupId: number;        
    nfirsIncidentTypeId: number;        
    nfirsAidGivenReceivedEntryId: number;        
    nfirsFDReceivedAidEntryId: number;        
    otherFDId: string;        
    lookupStateId: number;        
    incidentNumberOfFireDeptReceivingAid: string;
}export interface INfirsBasicInvolvedInputDto extends IUpdatableInputModelBaseDto {        
    reportId: number;        
    name: string;        
    businessName: string;        
    nfirsInvolvementEntryId: number;        
    otherInvolvement: string;        
    phoneNumber: string;        
    addressNumber: string;        
    streetOrHighway: string;        
    city: string;        
    zipcode: string;        
    isCasualty: boolean;        
    remarks: string;        
    otherTypeInvolved: string;
}
export interface INfirsBasicInvolvedGridOutputDto extends IGridOutputBaseDto {        
    nfirsBasicInvolvedOutputDtos: INfirsBasicInvolvedOutputDto[];
}
export interface INfirsBasicInvolvedOutputDto extends IUpdatableOutputModelBaseDto {        
    name: string;        
    businessName: string;        
    nfirsInvolvementEntryId: number;        
    otherInvolvement: string;        
    involvementDescription: string;        
    phoneNumber: string;        
    addressNumber: string;        
    streetOrHighway: string;        
    city: string;        
    zipcode: string;        
    isCasualty: boolean;        
    remarks: string;        
    otherTypeInvolved: string;
}export interface INfirsBasicLocationOutputDto extends IUpdatableOutputModelBaseDto {        
    nfirsLocationTypeId: NfirsLocationTypeEnum;        
    nfirsStreetTypeEntryId: number;        
    numberMilePost: string;        
    prefix: string;        
    streetHwy: string;        
    suffix: string;        
    suite: string;        
    city: string;        
    zipCode: string;        
    lookupStateId: number;        
    crossStreetDirections: string;
}
export interface INfirsBasicLocationInputDto extends IUpdatableInputModelBaseDto {        
    reportId: number;        
    nfirsLocationTypeId: NfirsLocationTypeEnum;        
    nfirsStreetTypeEntryId: number;        
    numberMilePost: string;        
    prefix: string;        
    streetHwy: string;        
    suffix: string;        
    suite: string;        
    city: string;        
    zipCode: string;        
    lookupStateId: number;        
    crossStreetDirections: string;
}
 

export enum NfirsLocationTypeEnum {
    StreetAddress = 1,
    Intersection = 2,
    InFrontOf = 3,
    RearOf = 4,
    AdjacentTo = 5,
    Directions = 6,
    USNationalGrid = 7
}export interface INfirsBasicPropertyInputDto extends IUpdatableInputModelBaseDto {        
    reportId: number;        
    nfirsHazardousMaterialReleasedId: number;        
    nfirsMixedUsedId: number;        
    nfirsPropertyUseTypeId: number;        
    propertyLost: number;        
    propertyValue: number;        
    contentLost: number;        
    contentValue: number;        
    totalPropertySaved: number;        
    nfirsDetectorAlertedEntryId: number;
}
export interface INfirsBasicPropertyOutputDto extends IUpdatableOutputModelBaseDto {        
    nfirsHazardousMaterialReleasedId: number;        
    nfirsMixedUsedId: number;        
    nfirsPropertyUseTypeId: number;        
    propertyLost: number;        
    propertyValue: number;        
    contentLost: number;        
    contentValue: number;        
    totalPropertySaved: number;        
    nfirsDetectorAlertedEntryId: number;
}export interface INfirsChangeLogOutputDto {        
    reportId: number;        
    nfirsLoggingTabsEnum: NfirsLoggingTabsEnum;        
    description: string;        
    recordId: number;        
    personName: string;        
    dateChanged: Date;        
    userId: number;        
    id: string;
}
export interface INfirsChangeLogSearchInputDto {        
    reportId: number;        
    tab: NfirsLoggingTabsEnum;        
    userId: number;
}


export interface INfirsChangeLogGridOutputDto extends IGridOutputBaseDto {        
    nfirsChangeLogOutputDtos: INfirsChangeLogOutputDto[];
}export interface IDynamicNfirsCheckRequestLimitOutputDto {        
    isUnderLimit: boolean;        
    message: string;
}
export interface IDynamicNfirsCheckRequestLimitInputDto {        
    start: Date;        
    end: Date;        
    status: NfirsReportStatusEnum;
}
export interface IDynamicReportModuleOutputDto {        
    name: string;        
    id: string;
}
export interface IDynamicReportTabOutputDto {        
    name: string;        
    moduleId: string;        
    id: string;
}
export interface IDynamicReportFieldOutputDto {        
    name: string;        
    moduleId: string;        
    tabId: string;        
    id: string;
}
export interface INfirsDynamicReportRequestDto {        
    start: Date;        
    end: Date;        
    status: NfirsReportStatusEnum;        
    departmentId: number;        
    isBasicModuleIncluded: boolean;        
    basicDynamicRequestDto: IBasicDynamicRequestDto;        
    isApparatusPersonnelModuleIncluded: boolean;        
    apparatusPersonnelDynamicRequestDto: IApparatusPersonnelDynamicRequestDto;        
    isFireModuleIncluded: boolean;        
    fireDynamicRequestDto: IFireDynamicRequestDto;        
    isStructureFireModuleIncluded: boolean;        
    structureFireDynamicRequestDto: IStructureFireDynamicRequestDto;        
    isHazmatModuleIncluded: boolean;        
    hazmatDynamicRequestDto: IHazmatDynamicRequestDto;        
    isArsonModuleIncluded: boolean;        
    arsonDynamicRequestDto: IArsonDynamicRequestDto;
}
export interface IBasicDynamicRequestDto {        
    isBasicAuthorityTabIncluded: boolean;        
    authorityDynamicRequestDto: IBasicAuthorityDynamicRequestDto;        
    isBasicIncidentTabIncluded: boolean;        
    incidentDynamicRequestDto: IBasicIncidentDynamicRequestDto;        
    isBasicLocationTabIncluded: boolean;        
    locationDynamicRequestDto: IBasicLocationDynamicRequestDto;        
    isBasicDatesTabIncluded: boolean;        
    datesDynamicRequestDto: IBasicDatesDynamicRequestDto;        
    isBasicActionsTabIncluded: boolean;        
    actionsDynamicRequestDto: IBasicActionsDynamicRequestDto;        
    isBasicPropertyTabIncluded: boolean;        
    propertyDynamicRequestDto: IBasicPropertyDynamicRequestDto;        
    isBasicInvolvedTabIncluded: boolean;        
    involvedDynamicRequestDto: IBasicInvolvedDynamicRequestDto;        
    isBasicRemarksTabIncluded: boolean;        
    remarksDynamicRequestDto: IBasicRemarksDynamicRequestDto;
}
export interface IBasicAuthorityDynamicRequestDto {        
    officerInChargedAgencyId: boolean;        
    officerInChargeDate: boolean;        
    officerInChargeFullName: boolean;        
    officerInChargeAssignment: boolean;        
    reportingOfficerAgencyId: boolean;        
    reportingOfficerDate: boolean;        
    reportingOfficerFullName: boolean;        
    reportingOfficerAssignment: boolean;
}
export interface IBasicIncidentDynamicRequestDto {        
    exposure: boolean;        
    nfirsIncidentGroup: boolean;        
    nfirsIncidentType: boolean;        
    nfirsFDReceivedAidId: boolean;        
    state: boolean;        
    incidentNumberOfFireDeptReceivingAid: boolean;        
    nfirsAidGivenReceived: boolean;
}
export interface IBasicLocationDynamicRequestDto {        
    nfirsLocationType: boolean;        
    nfirsStreetType: boolean;        
    numberMilePost: boolean;        
    prefix: boolean;        
    streetHwy: boolean;        
    suffix: boolean;        
    suite: boolean;        
    city: boolean;        
    zipCode: boolean;        
    state: boolean;        
    crossStreetDirections: boolean;
}
export interface IBasicDatesDynamicRequestDto {        
    alarmDate: boolean;        
    arrivalDate: boolean;        
    incidentControlledDate: boolean;        
    lastUnitClearedDate: boolean;        
    shift: boolean;        
    alarms: boolean;        
    district: boolean;
}
export interface IBasicActionsDynamicRequestDto {        
    nfirsActionTaken1: boolean;        
    nfirsActionTaken2: boolean;        
    nfirsActionTaken3: boolean;        
    suppressionPersonnel: boolean;        
    suppressionApparatus: boolean;        
    emsPersonnel: boolean;        
    emsApparatus: boolean;        
    otherPersonnel: boolean;        
    otherApparatus: boolean;        
    fireServiceDeaths: boolean;        
    fireServiceInjuries: boolean;        
    civilianDeaths: boolean;        
    civilianInjuries: boolean;
}
export interface IBasicPropertyDynamicRequestDto {        
    nfirsHazardousMaterialReleasedType: boolean;        
    nfirsMixedUseType: boolean;        
    nfirsPropertyUseType: boolean;        
    propertyLost: boolean;        
    propertyValue: boolean;        
    contentLost: boolean;        
    contentValue: boolean;        
    totalPropertySaved: boolean;        
    nfirsDetectorAlerted: boolean;
}
export interface IBasicInvolvedDynamicRequestDto {        
    name: boolean;        
    businessName: boolean;        
    nfirsInvolvement: boolean;        
    otherInvolvement: boolean;        
    phoneNumber: boolean;        
    addressNumber: boolean;        
    streetOrHighway: boolean;        
    city: boolean;        
    zipcode: boolean;        
    isCasualty: boolean;        
    remarks: boolean;        
    otherTypeInvolved: boolean;
}
export interface IBasicRemarksDynamicRequestDto {        
    remarks: boolean;        
    otherDatePublishedTypeInvolved: boolean;        
    fileName: boolean;        
    user: boolean;
}
export interface IApparatusPersonnelDynamicRequestDto {        
    isApparatusPersonnelApparatusTabIncluded: boolean;        
    apparatusDynamicRequestDto: IApparatusDynamicRequestDto;        
    isApparatusPersonnelPersonnelTabIncluded: boolean;        
    personnelDynamicRequestDto: IPersonnelDynamicRequestDto;
}
export interface IApparatusDynamicRequestDto {        
    apparatusId: boolean;        
    nfirsApparatusType: boolean;        
    dispatchDateTime: boolean;        
    arrivalDateTime: boolean;        
    clearDateTime: boolean;        
    nfirsApparatusUseType: boolean;        
    numberOfPeople: boolean;        
    isSent: boolean;        
    nfirsActionTaken1: boolean;        
    nfirsActionTaken2: boolean;
}
export interface IPersonnelDynamicRequestDto {        
    personnelEmpId: boolean;        
    firstName: boolean;        
    lastName: boolean;        
    rank: boolean;        
    attend: boolean;        
    nfirsActionTaken: boolean;
}
export interface IFireDynamicRequestDto {        
    isFirePropertyTabIncluded: boolean;        
    firePropertyDynamicRequestDto: IFirePropertyDynamicRequestDto;        
    isFireIgnitionTabIncluded: boolean;        
    fireIgnitionDynamicRequestDto: IFireIgnitionDynamicRequestDto;        
    isFireEquipmentInvolvedTabIncluded: boolean;        
    fireEquipmentInvolvedDynamicRequestDto: IFireEquipmentInvolvedDynamicRequestDto;        
    isFireMobilePropertyTabIncluded: boolean;        
    fireMobilePropertyDynamicRequestDto: IFireMobilePropertyDynamicRequestDto;
}
export interface IFirePropertyDynamicRequestDto {        
    numResidentialUnits: boolean;        
    numBuildingsInvolved: boolean;        
    numAcresBurned: boolean;        
    notResidential: boolean;        
    notBuilding: boolean;        
    acresBurnedEnum: boolean;        
    nfirsOnSiteMaterial: boolean;        
    nfirsOnSiteMaterialStorageUse: boolean;
}
export interface IFireIgnitionDynamicRequestDto {        
    nfirsAreaOfOrigin: boolean;        
    nfirsHeatSource: boolean;        
    isContainedToObjectOfOrigin: boolean;        
    nfirsItemFirstIgnited: boolean;        
    nfirsTypeOfMaterial: boolean;        
    nfirsCauseOfIgnition: boolean;        
    isNoFactorContributing: boolean;        
    contributingFactor1: boolean;        
    contributingFactor2: boolean;        
    wasAsleep: boolean;        
    wasImpaired: boolean;        
    wasUnattendedPerson: boolean;        
    wasMentallyDisabled: boolean;        
    wasPhysicallyDisabled: boolean;        
    wasMultiplePersonsInvolved: boolean;        
    wasAgeFactor: boolean;        
    estimatedAge: boolean;        
    gender: boolean;
}
export interface IFireEquipmentInvolvedDynamicRequestDto {        
    isEquipmentInvolved: boolean;        
    brand: boolean;        
    serialNumber: boolean;        
    year: boolean;        
    model: boolean;        
    nfirsEquipmentType: boolean;        
    nfirsPowerSource: boolean;        
    isPortable: boolean;        
    isFireSuppression: boolean;        
    nfirsFireSuppression1: boolean;        
    nfirsFireSuppression2: boolean;        
    nfirsFireSuppression3: boolean;
}
export interface IFireMobilePropertyDynamicRequestDto {        
    isMobilePropertyInvolved: boolean;        
    nfirsMobilePropertyInvolvement: boolean;        
    nfirsMobilePropertyType: boolean;        
    nfirsMobilePropertyMake: boolean;        
    model: boolean;        
    year: boolean;        
    licencePlateNumber: boolean;        
    state: boolean;        
    vin: boolean;
}
export interface IStructureFireDynamicRequestDto {        
    isStructureFireStructureTypeTabIncluded: boolean;        
    structureFireStructureTypeDynamicRequestDto: IStructureFireStructureTypeDynamicRequestDto;        
    isStructureFireDamageTabIncluded: boolean;        
    structureFireDamageDynamicRequestDto: IStructureFireDamageDynamicRequestDto;        
    isStructureFireDetectorTabIncluded: boolean;        
    structureFireDetectorDynamicRequestDto: IStructureFireDetectorDynamicRequestDto;        
    isStructureFireAesTabIncluded: boolean;        
    structureFireAesDynamicRequestDto: IStructureFireAesDynamicRequestDto;
}
export interface IStructureFireStructureTypeDynamicRequestDto {        
    nfirsStructureType: boolean;        
    nfirsItemFirstIgnited: boolean;        
    nfirsTypeOfMaterialContributingToFlameSpread: boolean;        
    nfirsStructureBuildingType: boolean;        
    storiesBelowGrade: boolean;        
    storiesAboveGrade: boolean;        
    width: boolean;        
    length: boolean;        
    totalSquareFeet: boolean;
}
export interface IStructureFireDamageDynamicRequestDto {        
    storyOfFireOrigin: boolean;        
    isBelowGrade: boolean;        
    nfirsStructureFireFireSpreadEnum: boolean;        
    noStoriesWithMinorDamage: boolean;        
    noStoriesWithSignificantDamage: boolean;        
    noStoriesWithHeavyDamage: boolean;        
    noStoriesWithExtremeDamage: boolean;
}
export interface IStructureFireDetectorDynamicRequestDto {        
    nfirsStructureFireDetectorPresence: boolean;        
    nfirsStructureFireDetectorType: boolean;        
    nfirsStructureFireDetectorPowerSupply: boolean;        
    nfirsStructureFireDetectorOperation: boolean;        
    nfirsStructureFireDetectorEffectiveness: boolean;        
    nfirsStructureFireDetectorFailureReason: boolean;
}
export interface IStructureFireAesDynamicRequestDto {        
    numSprinklers: boolean;        
    nfirsStructureFireAesPresence: boolean;        
    nfirsStructureFireAesType: boolean;        
    nfirsStructureFireAesOperation: boolean;        
    nfirsStructureFireAesFailureReason: boolean;
}
export interface IHazmatDynamicRequestDto {        
    isHazmatAreaTabIncluded: boolean;        
    hazmatAreaDynamicRequestDto: IHazmatAreaDynamicRequestDto;        
    isHazmatCauseTabIncluded: boolean;        
    hazmatCauseDynamicRequestDto: IHazmatCauseDynamicRequestDto;        
    isHazmatChemicalTabIncluded: boolean;        
    hazmatChemicalDynamicRequestDto: IHazmatChemicalDynamicRequestDto;        
    isHazmatMobilePropertyTabIncluded: boolean;        
    hazmatMobilePropertyDynamicRequestDto: IHazmatMobilePropertyDynamicRequestDto;        
    isHazmatEquipmentInvolvedTabIncluded: boolean;        
    hazmatEquipmentInvolvedDynamicRequestDto: IHazmatEquipmentInvolvedDynamicRequestDto;
}
export interface IHazmatAreaDynamicRequestDto {        
    isReleasedBelowGrade: boolean;        
    isReleasedInsideStructure: boolean;        
    storyReleasedOn: boolean;        
    isReleasedOutStructure: boolean;        
    populationDensity: boolean;        
    nfirsHazmatActionTaken1: boolean;        
    nfirsHazmatActionTaken2: boolean;        
    nfirsHazmatActionTaken3: boolean;        
    numberPeopleEvacuated: boolean;        
    numberBuildingEvacuated: boolean;        
    nfirsIgnitionOrRelease: boolean;        
    areaAffectedUnits: boolean;        
    areaAffected: boolean;        
    areaEvacuatedUnits: boolean;        
    areaEvacuated: boolean;
}
export interface IHazmatCauseDynamicRequestDto {        
    nfirsHazmatCauseOfRelease: boolean;        
    hazmatDisposition: boolean;        
    nfirsFactorsContributingToRelease1: boolean;        
    nfirsFactorsContributingToRelease2: boolean;        
    nfirsFactorsContributingToRelease3: boolean;        
    numCivilianInjuries: boolean;        
    numCivilianDeaths: boolean;        
    nfirsFactorsContributingToMitigation1: boolean;        
    nfirsFactorsContributingToMitigation2: boolean;        
    nfirsFactorsContributingToMitigation3: boolean;
}
export interface IHazmatChemicalDynamicRequestDto {        
    unNumber: boolean;        
    nfirsHazmatDotClassification: boolean;        
    casRegistrationNumber: boolean;        
    name: boolean;        
    nfirsChemicalContainerType: boolean;        
    estimatedContainerCapacityUnits: boolean;        
    estimatedContainerCapacity: boolean;        
    estimatedAmountReleasedUnits: boolean;        
    estimatedAmountReleased: boolean;        
    nfirsPhysicalState: boolean;        
    nfirsReleasedInto: boolean;
}
export interface IHazmatMobilePropertyDynamicRequestDto {        
    isMobilePropertyInvolved: boolean;        
    nfirsMobilePropertyType: boolean;        
    nfirsMobilePropertyMake: boolean;        
    model: boolean;        
    year: boolean;        
    licencePlateNumber: boolean;        
    state: boolean;        
    dotNumber: boolean;
}
export interface IHazmatEquipmentInvolvedDynamicRequestDto {        
    isEquipmentInvolved: boolean;        
    brand: boolean;        
    serialNumber: boolean;        
    year: boolean;        
    model: boolean;        
    nfirsEquipmentType: boolean;
}
export interface IArsonDynamicRequestDto {        
    isArsonInvestigationTabIncluded: boolean;        
    arsonInvestigationDynamicRequestDto: IArsonInvestigationDynamicRequestDto;        
    isArsonDetailsTabIncluded: boolean;        
    arsonDetailsDynamicRequestDto: IArsonDetailsDynamicRequestDto;        
    isArsonAgencyReferralTabIncluded: boolean;        
    arsonAgencyReferralDynamicRequestDto: IArsonAgencyReferralDynamicRequestDto;        
    isArsonJuvenileTabIncluded: boolean;        
    arsonJuvenileDynamicRequestDto: IArsonJuvenileDynamicRequestDto;        
    isArsonRemarksTabIncluded: boolean;        
    arsonRemarksDynamicRequestDto: IArsonRemarksDynamicRequestDto;
}
export interface IArsonInvestigationDynamicRequestDto {        
    nfirsArsonInvestigationStatus: boolean;        
    nfirsAvailabilityOfFirstMaterialIgnited: boolean;        
    nfirsArsonSuspectedMotivationFactor1: boolean;        
    nfirsArsonSuspectedMotivationFactor2: boolean;        
    nfirsArsonSuspectedMotivationFactor3: boolean;
}
export interface IArsonDetailsDynamicRequestDto {        
    nfirsArsonApparentGroupInvolvement: boolean;        
    nfirsArsonMethod: boolean;        
    nfirsArsonExtentOfFireInvolvementArrival: boolean;        
    nfirsArsonIncendiaryDevice: boolean;        
    nfirsArsonIgnitionDelayDevice: boolean;        
    nfirsArsonFuel: boolean;        
    initialObservations: boolean;        
    laboratoriesUsed: boolean;        
    otherInvestigations: boolean;
}
export interface IArsonAgencyReferralDynamicRequestDto {        
    isAgencyReferral: boolean;        
    agencyName: boolean;        
    caseNumber: boolean;        
    number: boolean;        
    prefix: boolean;        
    street: boolean;        
    suffix: boolean;        
    ori: boolean;        
    postOfficeBox: boolean;        
    apt: boolean;        
    city: boolean;        
    fid: boolean;        
    state: boolean;        
    zipCode: boolean;        
    agencyPhoneNumber: boolean;        
    fdid: boolean;
}
export interface IArsonJuvenileDynamicRequestDto {        
    subjectNumber: boolean;        
    age: boolean;        
    dateOfBirth: boolean;        
    gender: boolean;        
    nfirsJuvenileRace: boolean;        
    ethnicity: boolean;        
    nfirsJuvenileFamilyType: boolean;        
    nfirsJuvenileDisposition: boolean;        
    riskFactors: boolean;
}
export interface IArsonRemarksDynamicRequestDto {        
    remarks: boolean;        
    otherDatePublishedTypeInvolved: boolean;        
    fileName: boolean;        
    user: boolean;
}


 

export enum DynamicReportEnum {
    Nfirs = 1
}export interface INfirsPropertyUseTypeFavoriteInputDto {        
    id: number;
}
export interface INfirsPropertyUseTypeFavoriteOutputDto {        
    id: number;        
    description: string;        
    isFavorite: boolean;        
    externalId: string;
}
export interface INfirsIncidentTypeFavoriteInputDto {        
    id: number;
}
export interface INfirsIncidentTypeFavoriteOutputDto {        
    id: number;        
    description: string;        
    nfirsIncidentGroupId: number;        
    isFavorite: boolean;
}
export interface INfirsActionTakenFavoriteInputDto {        
    id: number;
}
export interface INfirsActionTakenFavoriteOutputDto {        
    id: number;        
    description: string;        
    isFavorite: boolean;        
    externalId: string;
}
export interface INfirsOnSiteMaterialFavoriteInputDto {        
    id: number;
}
export interface INfirsOnSiteMaterialFavoriteOutputDto {        
    id: number;        
    description: string;        
    externalId: string;        
    onSiteMaterialSuperSection: OnSiteMaterialSuperSectionEnum;        
    onSiteMaterialsSubSection: OnSiteMaterialsSubSectionEnum;        
    isFavorite: boolean;
}
export interface INfirsAreaOfOriginEntryFavoriteInputDto {        
    id: number;
}
export interface INfirsAreaOfOriginEntryFavoriteOutputDto {        
    id: number;        
    description: string;        
    externalId: string;        
    nfirsAreaOfOriginSection: NfirsAreaOfOriginSectionEnum;        
    isFavorite: boolean;
}
export interface INfirsHeatSourceEntryFavoriteInputDto {        
    id: number;
}
export interface INfirsHeatSourceEntryFavoriteOutputDto {        
    id: number;        
    description: string;        
    externalId: string;        
    nfirsHeatSourceSection: NfirsHeatSourceSectionEnum;        
    isFavorite: boolean;
}
export interface INfirsItemFirstIgnitedFavoriteInputDto {        
    id: number;
}
export interface INfirsItemFirstIgnitedFavoriteOutputDto {        
    id: number;        
    description: string;        
    externalId: string;        
    nfirsItemFirstIgnitedSection: NfirsItemFirstIgnitedSectionEnum;        
    isFavorite: boolean;
}
export interface INfirsTypeOfMaterialFavoriteInputDto {        
    id: number;
}
export interface INfirsTypeOfMaterialFavoriteOutputDto {        
    id: number;        
    description: string;        
    externalId: string;        
    nfirsTypeOfMaterialSection: NfirsTypeOfMaterialSectionEnum;        
    isFavorite: boolean;
}
export interface INfirsContributingFactorFavoriteInputDto {        
    id: number;
}
export interface INfirsContributingFactorFavoriteOutputDto {        
    id: number;        
    description: string;        
    externalId: string;        
    nfirsContributingFactorSection: NfirsContributingFactorSectionEnum;        
    isFavorite: boolean;
}
export interface INfirsEquipmentTypeFavoriteInputDto {        
    id: number;
}
export interface INfirsEquipmentTypeFavoriteOutputDto {        
    id: number;        
    description: string;        
    externalId: string;        
    nfirsEquipmentTypeSection: NfirsEquipmentTypeSectionEnum;        
    isFavorite: boolean;
}
export interface INfirsFireSuppressionFavoriteInputDto {        
    id: number;
}
export interface INfirsFireSuppressionFavoriteOutputDto {        
    id: number;        
    description: string;        
    externalId: string;        
    nfirsFireSuppressionSection: NfirsFireSuppressionSectionEnum;        
    isFavorite: boolean;
}
export interface INfirsMobilePropertyTypeFavoriteInputDto {        
    id: number;
}
export interface INfirsMobilePropertyTypeFavoriteOutputDto {        
    id: number;        
    description: string;        
    externalId: string;        
    mobilePropertyTypeSection: MobilePropertyTypeSectionEnum;        
    isFavorite: boolean;
}
export interface INfirsTypeOfMaterialContributingToFlameSpreadFavoriteInputDto {        
    id: number;
}
export interface INfirsTypeOfMaterialContributingToFlameSpreadFavoriteOutputDto {        
    id: number;        
    description: string;        
    externalId: string;        
    nfirsTypeOfMaterialContributingToFlameSpreadEntryEnum: NfirsTypeOfMaterialContributingToFlameSpreadEntryEnum;        
    isFavorite: boolean;
}
export interface INfirsHazmatActionTakenFavoriteInputDto {        
    id: number;
}
export interface INfirsHazmatActionTakenFavoriteOutputDto {        
    id: number;        
    description: string;        
    externalId: string;        
    isFavorite: boolean;
}
export interface INfirsFactorContributingToReleaseFavoriteInputDto {        
    id: number;
}
export interface INfirsFactorContributingToReleaseFavoriteOutputDto {        
    id: number;        
    description: string;        
    externalId: string;        
    isFavorite: boolean;
}
export interface INfirsFactorContributingToMitigationFavoriteInputDto {        
    id: number;
}
export interface INfirsFactorContributingToMitigationFavoriteOutputDto {        
    id: number;        
    description: string;        
    externalId: string;        
    isFavorite: boolean;
}
export interface INfirsChemicalContainerTypeFavoriteInputDto {        
    id: number;
}
export interface INfirsChemicalContainerTypeFavoriteOutputDto {        
    id: number;        
    description: string;        
    externalId: string;        
    isFavorite: boolean;
}
export interface INfirsMobilePropertyMakeFavoriteInputDto {        
    id: number;
}
export interface INfirsMobilePropertyMakeFavoriteOutputDto {        
    id: number;        
    description: string;        
    externalId: string;        
    isFavorite: boolean;
}export interface INfirsFireEquipmentInvolvedInputDto extends IUpdatableInputModelBaseDto {        
    reportId: number;        
    isEquipmentInvolved: boolean;        
    nfirsEquipmentId: number;        
    nfirsPowerSourceEntryId: number;        
    isPortable: boolean;        
    isFireSuppression: boolean;        
    nfirsFireSuppressionEntry1Id: number;        
    nfirsFireSuppressionEntry2Id: number;        
    nfirsFireSuppressionEntry3Id: number;        
    brand: string;        
    serialNumber: string;        
    year: string;        
    model: string;        
    nfirsEquipmentTypeEntryId: number;
}
export interface INfirsFireEquipmentInvolvedOutputDto extends IUpdatableOutputModelBaseDto {        
    isEquipmentInvolved: boolean;        
    nfirsEquipmentId: number;        
    nfirsPowerSourceEntryId: number;        
    isPortable: boolean;        
    isFireSuppression: boolean;        
    nfirsFireSuppressionEntry1Id: number;        
    nfirsFireSuppressionEntry2Id: number;        
    nfirsFireSuppressionEntry3Id: number;        
    brand: string;        
    serialNumber: string;        
    year: string;        
    model: string;        
    nfirsEquipmentTypeEntryId: number;
}
 

export enum NfirsPowerSourceSectionEnum {
    Electrical = 1,
    GasFuels = 2,
    LiquidFuels = 3,
    SolidFuels = 4,
    OtherPowerSources = 5
}
export enum NfirsFireSuppressionSectionEnum {
    BuildingConstructionOrDesign = 1,
    ActOrOmission = 2,
    OnSiteMaterials = 3,
    Delays = 4,
    ProtectiveEquipment = 5,
    EgressExitProblems = 6,
    NaturalConditions = 7,
    OtherFireSuppressionFactors = 8
}
export enum NfirsEquipmentTypeSectionEnum {
    HeatingVentingAirConditioning = 1,
    ElectricalDistributionLightingPowerTransfer = 2,
    ShopToolsIndustrialEquipment = 3,
    CommercialMedicalEquipment = 4,
    GardenToolsAgriculturalEquipment = 5,
    KitchenAndCookingEquipment = 6,
    ElectronicEquipment = 7,
    PersonalHouseholdEquipment = 8
}export interface INfirsFireIgnitionInputDto extends IUpdatableInputModelBaseDto {        
    reportId: number;        
    nfirsAreaOfOriginEntryId: number;        
    nfirsHeatSourceEntryId: number;        
    isContainedToObjectOfOrigin: boolean;        
    nfirsItemFirstIgnitedEntryId: number;        
    nfirsTypeOfMaterialEntryId: number;        
    nfirsCauseOfIgnitionEntryId: number;        
    isNoFactorContributing: boolean;        
    contributingFactorEntry1Id: number;        
    contributingFactorEntry2Id: number;        
    wasAsleep: boolean;        
    wasImpaired: boolean;        
    wasUnattendedPerson: boolean;        
    wasMentallyDisabled: boolean;        
    wasPhysicallyDisabled: boolean;        
    wasMultiplePersonsInvolved: boolean;        
    wasAgeFactor: boolean;        
    estimatedAge: number;        
    gender: NfirsJuvenileMaleFemaleEnum;
}
export interface INfirsFireIgnitionOutputDto extends IUpdatableOutputModelBaseDto {        
    nfirsAreaOfOriginEntryId: number;        
    nfirsHeatSourceEntryId: number;        
    isContainedToObjectOfOrigin: boolean;        
    nfirsItemFirstIgnitedEntryId: number;        
    nfirsTypeOfMaterialEntryId: number;        
    nfirsCauseOfIgnitionEntryId: number;        
    isNoFactorContributing: boolean;        
    contributingFactorEntry1Id: number;        
    contributingFactorEntry2Id: number;        
    wasAsleep: boolean;        
    wasImpaired: boolean;        
    wasUnattendedPerson: boolean;        
    wasMentallyDisabled: boolean;        
    wasPhysicallyDisabled: boolean;        
    wasMultiplePersonsInvolved: boolean;        
    wasAgeFactor: boolean;        
    estimatedAge: number;        
    gender: NfirsJuvenileMaleFemaleEnum;
}
 

export enum NfirsAreaOfOriginSectionEnum {
    MeansOfEgress = 1,
    AssemblyOrSalesAreas = 2,
    FunctionAreas = 3,
    TechnicalProcessingAreas = 4,
    StorageAreas = 5,
    ServiceAres = 6,
    ServiceOrEquipmentAreas = 7,
    StructuralAreas = 8,
    TransportationVehicleAreas = 9,
    OutsideAreas = 10,
    OtherAreasOfFireOrigin = 11
}
export enum NfirsHeatSourceSectionEnum {
    OperatingEquipment = 1,
    HotSmolderingObject = 2,
    ExplosiveFireworks = 3,
    OtherOpenFlameOrSmokingMaterials = 4,
    ChemicalNaturalHeatSource = 5,
    HeatSpreadFromAnotherFire = 6,
    OtherHeatSources = 7
}
export enum NfirsItemFirstIgnitedSectionEnum {
    StructuralComponentFinish = 1,
    FurnitureUtensils = 2,
    SoftGoods = 3,
    AdornmentRecreationalMaterSigns = 4,
    StorageSupplies = 5,
    LiquidsPipingFilters = 6,
    OrganicMaterials = 7,
    GeneralMaterials = 8,
    GeneralMaterialsContinued = 9
}
export enum NfirsTypeOfMaterialSectionEnum {
    FlammableGas = 1,
    FlammableOrCombustibleLiquid = 2,
    VolatileSolidOrChemical = 3,
    Plastics = 4,
    NaturalPlastic = 5,
    WoodOrPaperProcessed = 6,
    FabricTextilesFur = 7,
    MaterialCompoundedWithOil = 8
}
export enum NfirsContributingFactorSectionEnum {
    MisuseOfMaterial = 1,
    MechanicalFailure = 2,
    ElectricalFailure = 3,
    DesignManufacturingInstallationDeficiency = 4,
    OperationalDeficiency = 5,
    NaturalCondition = 6,
    FireSpreadOrControl = 7,
    OtherHumanFactors = 8
}export interface INfirsFireMobilePropertyInputDto extends IUpdatableInputModelBaseDto {        
    reportId: number;        
    isMobilePropertyInvolved: boolean;        
    nfirsMobilePropertyInvolvementEntryId: number;        
    nfirsMobilePropertyTypeEntryId: number;        
    nfirsMobilePropertyMakeEntryId: number;        
    model: string;        
    year: string;        
    licencePlateNumber: string;        
    stateId: number;        
    vin: string;
}
export interface INfirsFireMobilePropertyOutputDto extends IUpdatableOutputModelBaseDto {        
    reportId: number;        
    nfirsMobilePropertyInvolvementEntryId: number;        
    isMobilePropertyInvolved: boolean;        
    nfirsMobilePropertyTypeEntryId: number;        
    nfirsMobilePropertyMakeEntryId: number;        
    model: string;        
    year: string;        
    licencePlateNumber: string;        
    stateId: number;        
    vin: string;
}
 

export enum MobilePropertyTypeSectionEnum {
    PassengerRoadVehicles = 1,
    FreightRoadVehicles = 2,
    RailTransportVehicles = 3,
    WaterVessels = 4,
    Aircraft = 5,
    IndustrialAgriculturalConstructionVehicles = 6,
    MobilePropertyMisc = 7
}export interface INfirsFirePropertyInputDto extends IUpdatableInputModelBaseDto {        
    reportId: number;        
    numResidentialUnits: number;        
    numBuildingsInvolved: number;        
    numAcresBurned: number;        
    notResidential: boolean;        
    notBuilding: boolean;        
    acresBurnedEnum: NfirsFirePropertyAcresBurnedEnum;        
    nfirsOnSiteMaterialEntryId: number;        
    nfirsOnSiteMaterialStorageUseEntryId: number;
}
export interface INfirsFirePropertyOutputDto extends IUpdatableOutputModelBaseDto {        
    numResidentialUnits: number;        
    numBuildingsInvolved: number;        
    numAcresBurned: number;        
    notResidential: boolean;        
    notBuilding: boolean;        
    acresBurnedEnum: NfirsFirePropertyAcresBurnedEnum;        
    nfirsOnSiteMaterialEntryId: number;        
    nfirsOnSiteMaterialStorageUseEntryId: number;
}
 

export enum NfirsFirePropertyAcresBurnedEnum {
    None = 1,
    LessThan1 = 2,
    MoreThan1 = 3
}
export enum OnSiteMaterialSuperSectionEnum {
    FoodBeveragesAgriculture = 1,
    PersonalAndHomeProducts = 2,
    RawMaterials = 3,
    PaperProductsRope = 4,
    FlammablesChemicalsPlastics = 5,
    ConstructionMachineryMetals = 6,
    AppliancesElectronicsMedicalLaboratory = 7,
    VehiclesVehicleParts = 8,
    OtherProducts = 9
}
export enum OnSiteMaterialsSubSectionEnum {
    Food = 1,
    Beverage = 2,
    Agriculture = 3,
    FoodBeverageAgricultureOther = 4,
    Fabrics = 5,
    WearableProducts = 6,
    Accessories = 7,
    Furnishings = 8,
    PersonalAndHomeProductsOther = 9,
    Wood = 10,
    Fibers = 11,
    AnimalSkins = 12,
    OtherRawMaterials = 13,
    PaperProducts = 15,
    RopeTwinCordage = 16,
    PaperProductsRopeOther = 17,
    FlammablesCombustibleLiquids = 18,
    FlammableGases = 19,
    SolidFuelCoalType = 20,
    ChemicalsDrugs = 21,
    RadioactiveMaterials = 22,
    FlammablesChemicalsPlasticsOthers = 23,
    MachineryTools = 24,
    ConstructionSupplies = 25,
    FloorWallCoverings = 26,
    MetalProducts = 27,
    ConstructionMachineryMetalOther = 28,
    AppliancesElectronics = 29,
    MedicalLaboratoryProducts = 30,
    AppliancesElectronicsMedicalLaboratoryOther = 31,
    MotorVehiclesAndParts = 32,
    Watercraft = 33,
    Aircraft = 34,
    Rail = 35,
    NonMotorizedVehicles = 36,
    ContainersPackingMaterials = 37,
    PreviouslyOwnedProducts = 38,
    Weapons = 39,
    RecreationArtsProducts = 40,
    MixedSalesProducts = 41,
    DiscardedMaterial = 42,
    OtherOnSiteMaterials = 43
}export enum NfirsLoggingActionEnum {
    Create = 0,
    Update = 1,
    Delete = 2,
    Lock = 3,
    LockWithInvalid = 4
}
export enum NfirsLoggingTabsEnum {
    BasicAuthority = 0,
    BasicIncident = 1,
    BasicLocation = 2,
    BasicDate = 3,
    BasicAction = 4,
    BasicProperty = 5,
    BasicInvolved = 6,
    BasicRemarks = 7,
    Apparatus = 8,
    Personnel = 9,
    FireProperty = 10,
    FireIgnition = 11,
    FireEquipmentInvolved = 12,
    FireMobileProperty = 13,
    StructureFireStrcutureType = 14,
    StructureFireDamage = 15,
    StructureFireDetector = 16,
    StructureFireAes = 17,
    HazmatArea = 18,
    HazmatCause = 19,
    HazmatChemical = 20,
    HazmatMobileProperty = 21,
    HazmatEquipmentInvolved = 22,
    ArsonInvestigation = 23,
    ArsonDetail = 24,
    ArsonAgencyReferral = 25,
    ArsonJuvenile = 26,
    ArsonRemarks = 27,
    Report = 28,
    WildlandLocation = 29,
    WildlandIgnition = 30,
    WildlandProperty = 31,
    WildlandPeopleInvolved = 32,
    WildlandDetail = 33
}export interface INfirsIncidentGroupOutputDto {        
    description: string;        
    id: number;
}
export interface INfirsActionTakenOutputDto {        
    id: number;        
    externalId: string;        
    description: string;        
    nfirsActionsTakenSubsectionEnum: NfirsActionsTakenSubsectionEnum;
}
export interface INfirsPropertyUsedTypeOutputDto {        
    description: string;        
    id: number;        
    externalId: string;
}
export interface INfirsFdReceivedAidEntryOutputDto {        
    description: string;        
    externalId: string;        
    id: number;        
    reportHeaderId: number;
}
export interface INfirsApparatusTypeOutputDto {        
    description: string;        
    externalId: string;        
    generalApparatusType: GeneralApparatusTypeEnum;        
    id: number;
}
export interface INfirsApparatusUseTypeOutputDto {        
    description: string;        
    id: number;
}
export interface INfirsAidGivenReceivedEntryOutputDto {        
    description: string;        
    id: number;        
    externalId: string;
}
export interface INfirsMixedUseTypeOutputDto {        
    description: string;        
    externalId: string;        
    id: number;
}
export interface INfirsHazardousMaterialReleasedTypeOutputDto {        
    description: string;        
    externalId: string;        
    id: number;
}
export interface INfirsIncidentTypeOutputDto {        
    nfirsIncidentGroupId: number;        
    description: string;        
    id: number;
}
export interface INfirsOnSiteMaterialEntryOutputDto {        
    description: string;        
    externalId: string;        
    onSiteMaterialSuperSection: OnSiteMaterialSuperSectionEnum;        
    onSiteMaterialsSubSection: OnSiteMaterialsSubSectionEnum;        
    id: number;
}
export interface INfirsOnSiteMaterialStorageUseEntryOutputDto {        
    description: string;        
    externalId: string;        
    id: number;
}
export interface INfirsAreaOfOriginEntryOutputDto {        
    description: string;        
    externalId: string;        
    nfirsAreaOfOriginSection: NfirsAreaOfOriginSectionEnum;        
    id: number;
}
export interface INfirsHeatSourceEntryOutputDto {        
    description: string;        
    externalId: string;        
    nfirsHeatSourceSection: NfirsHeatSourceSectionEnum;        
    id: number;
}
export interface INfirsItemFirstIgnitedEntryOutputDto {        
    description: string;        
    externalId: string;        
    nfirsItemFirstIgnitedSection: NfirsItemFirstIgnitedSectionEnum;        
    id: number;
}
export interface INfirsTypeOfMaterialEntryOutputDto {        
    description: string;        
    externalId: string;        
    nfirsTypeOfMaterialSection: NfirsTypeOfMaterialSectionEnum;        
    id: number;
}
export interface INfirsCauseOfIgnitionEntryOutputDto {        
    description: string;        
    externalId: string;        
    id: number;
}
export interface INfirsContributingFactorEntryOutputDto {        
    description: string;        
    externalId: string;        
    nfirsContributingFactorSection: NfirsContributingFactorSectionEnum;        
    id: number;
}
export interface INfirsFireSuppressionEntryOutputDto {        
    description: string;        
    externalId: string;        
    nfirsFireSuppressionSection: NfirsFireSuppressionSectionEnum;        
    id: number;
}
export interface INfirsEquipmentTypeEntryOutputDto {        
    description: string;        
    externalId: string;        
    nfirsEquipmentTypeSection: NfirsEquipmentTypeSectionEnum;        
    id: number;
}
export interface INfirsHazmatActionTakenEntryOutputDto {        
    description: string;        
    externalId: string;        
    id: number;
}
export interface INfirsStructureTypeEntryOutputDto {        
    description: string;        
    externalId: string;        
    id: number;
}
export interface INfirsStructureBuildingTypeEntryOutputDto {        
    description: string;        
    externalId: string;        
    id: number;
}
export interface INfirsHazmatCauseOfReleaseEntryOutputDto {        
    description: string;        
    externalId: string;        
    id: number;
}
export interface INfirsFactorsContributingToReleaseEntryOutputDto {        
    description: string;        
    externalId: string;        
    id: number;
}
export interface INfirsFactorsContributingToMitigationEntryOutputDto {        
    description: string;        
    externalId: string;        
    id: number;
}
export interface INfirsTypeOfMaterialContributingToFlameSpreadEntryOutputDto {        
    nfirsTypeOfMaterialContributingToFlameSpreadEntryEnum: NfirsTypeOfMaterialContributingToFlameSpreadEntryEnum;        
    description: string;        
    externalId: string;        
    id: number;
}
export interface INfirsStructureFireDetectorPresenceEntryOutputDto {        
    description: string;        
    externalId: string;        
    id: number;
}
export interface INfirsStructureFireDetectorTypeEntryOutputDto {        
    description: string;        
    externalId: string;        
    id: number;
}
export interface INfirsStructureFireDetectorPowerSupplyEntryOutputDto {        
    description: string;        
    externalId: string;        
    id: number;
}
export interface INfirsStructureFireDetectorOperationEntryOutputDto {        
    description: string;        
    externalId: string;        
    id: number;
}
export interface INfirsStructureFireDetectorEffectivenessEntryOutputDto {        
    description: string;        
    externalId: string;        
    id: number;
}
export interface INfirsStructureFireDetectorFailureReasonEntryOutputDto {        
    description: string;        
    externalId: string;        
    id: number;
}
export interface INfirsHazmatDotClassificationEntryOutputDto {        
    description: string;        
    externalId: string;        
    nfirsHazmatDotClassificationSection: NfirsHazmatDotClassificationSectionEnum;        
    id: number;
}
export interface INfirsChemicalContainerTypeEntryOutputDto {        
    description: string;        
    externalId: string;        
    id: number;
}
export interface INfirsHazmatUnitsEntryOutputDto {        
    description: string;        
    externalId: string;        
    nfirsUnitTypeSection: NfirsUnitTypeSectionEnum;        
    id: number;
}
export interface INfirsPhysicalStateEntryOutputDto {        
    description: string;        
    externalId: string;        
    id: number;
}
export interface INfirsIgnitionOrReleaseEntryOutputDto {        
    description: string;        
    externalId: string;        
    id: number;
}
export interface INfirsStructureFireAesPresenceEntryOutputDto {        
    description: string;        
    externalId: string;        
    id: number;
}
export interface INfirsStructureFireAesTypeEntryOutputDto {        
    description: string;        
    externalId: string;        
    id: number;
}
export interface INfirsStructureFireAesOperationEntryOutputDto {        
    description: string;        
    externalId: string;        
    id: number;
}
export interface INfirsStructureFireAesFailureReasonEntryOutputDto {        
    description: string;        
    externalId: string;        
    id: number;
}
export interface INfirsMobilePropertyTypeEntryOutputDto {        
    description: string;        
    externalId: string;        
    mobilePropertyTypeSection: MobilePropertyTypeSectionEnum;        
    id: number;
}
export interface INfirsMobilePropertyMakeEntryOutputDto {        
    description: string;        
    externalId: string;        
    id: number;
}
export interface INfirsAvailabilityOfFirstMaterialIgnitedEntryOutputDto {        
    description: string;        
    externalId: string;        
    id: number;
}
export interface INfirsArsonSuspectedMotivationFactorEntryOutputDto {        
    description: string;        
    externalId: string;        
    id: number;
}
export interface INfirsArsonInitialObservationEntryOutputDto {        
    description: string;        
    externalId: string;        
    id: number;
}
export interface INfirsArsonLaboratoryUsedEntryOutputDto {        
    description: string;        
    externalId: string;        
    id: number;
}
export interface INfirsArsonOtherInvestigationInfoEntryOutputDto {        
    description: string;        
    externalId: string;        
    id: number;
}
export interface INfirsArsonPropertyOwnershipEntryOutputDto {        
    description: string;        
    externalId: string;        
    id: number;
}
export interface INfirsArsonApparentGroupInvolvementEntryOutputDto {        
    description: string;        
    externalId: string;        
    id: number;
}
export interface INfirsArsonEntryMethodEntryOutputDto {        
    description: string;        
    externalId: string;        
    id: number;
}
export interface INfirsArsonExtentOfFireInvolvementArrivalEntryOutputDto {        
    description: string;        
    id: number;
}
export interface INfirsArsonIncendiaryDeviceEntryOutputDto {        
    description: string;        
    externalId: string;        
    id: number;
}
export interface INfirsArsonIgnitionDelayDeviceEntryOutputDto {        
    description: string;        
    externalId: string;        
    id: number;
}
export interface INfirsArsonFuelEntryOutputDto {        
    description: string;        
    externalId: string;        
    id: number;
}
export interface INfirsJuvenileRaceEntryOutputDto {        
    description: string;        
    externalId: string;        
    id: number;
}
export interface INfirsJuvenileFamilyTypeEntryOutputDto {        
    description: string;        
    externalId: string;        
    id: number;
}
export interface INfirsJuvenileRiskFactorEntryOutputDto {        
    description: string;        
    externalId: string;        
    id: number;
}
export interface INfirsJuvenileDispositionEntryOutputDto {        
    description: string;        
    externalId: string;        
    id: number;
}
export interface INfirsPowerSourceEntryOutputDto {        
    description: string;        
    externalId: string;        
    nfirsPowerSourceSection: NfirsPowerSourceSectionEnum;        
    id: number;
}
export interface INfirsLocationSubsectionEntryOutputDto {        
    description: string;        
    externalId: string;        
    id: number;
}
export interface INfirsMeridianEntryOutputDto {        
    description: string;        
    externalId: string;        
    id: number;
}
export interface INfirsAreaTypeEntryOutputDto {        
    description: string;        
    externalId: string;        
    id: number;
}
export interface INfirsWildlandFireCauseEntryOutputDto {        
    description: string;        
    externalId: string;        
    id: number;
}
export interface INfirsHumanFactorsEntryOutputDto {        
    description: string;        
    externalId: string;        
    id: number;
}
export interface INfirsWeatherTypeEntryOutputDto {        
    description: string;        
    externalId: string;        
    id: number;
}
export interface INfirsWindDirectionEntryOutputDto {        
    description: string;        
    externalId: string;        
    id: number;
}
export interface INfirsFuelModelEntryOutputDto {        
    description: string;        
    externalId: string;        
    id: number;
}
export interface INfirsPersonResponsibleForFireEntryOutputDto {        
    description: string;        
    externalId: string;        
    id: number;
}
export interface INfirsActivityOfPersonInvolvedEntryOutputDto {        
    description: string;        
    externalId: string;        
    id: number;
}
export interface INfirsTypeOfRightOfWayEntryOutputDto {        
    description: string;        
    externalId: string;        
    id: number;
}
export interface INfirsPositionOnSlopeEntryOutputDto {        
    description: string;        
    externalId: string;        
    id: number;
}
export interface INfirsAspectEntryOutputDto {        
    description: string;        
    externalId: string;        
    id: number;
}
export interface INfirsFireDangerRatingEntryOutputDto {        
    description: string;        
    externalId: string;        
    id: number;
}
export interface INfirsPrivateTypeEntryOutputDto {        
    description: string;        
    externalId: string;        
    id: number;
}
export interface INfirsPublicTypeEntryOutputDto {        
    description: string;        
    externalId: string;        
    id: number;
}
export interface INfirsMobilePropertyInvolvementEntryOutputDto {        
    description: string;        
    externalId: string;        
    id: number;
}
export interface INfirsStreetTypeEntryOutputDto {        
    id: number;        
    abbreviation: string;        
    description: string;
}
export interface INfirsInvolvementEntryOutputDto {        
    id: number;        
    description: string;
}
export interface INfirsDetectorAlertedEntryOutputDto {        
    id: number;        
    externalId: string;        
    description: string;
}
export interface INfirsBasicAuthorityAssignmentTypeEntryOutputDto {        
    id: number;        
    externalId: string;        
    description: string;
}export interface INfirsHazmatAreaInputDto extends IUpdatableInputModelBaseDto {        
    reportId: number;        
    isReleasedBelowGrade: boolean;        
    isReleasedInsideStructure: boolean;        
    storyReleasedOn: number;        
    isReleasedOutStructure: boolean;        
    populationDensity: NfirsPopulationDensityEnum;        
    nfirsHazmatActionTakenEntry1Id: number;        
    nfirsHazmatActionTakenEntry2Id: number;        
    nfirsHazmatActionTakenEntry3Id: number;        
    numberPeopleEvacuated: number;        
    numberBuildingEvacuated: number;        
    nfirsIgnitionOrReleaseEntryId: number;        
    areaAffectedUnits: NfirsAreaAffectedUnitsEnum;        
    areaAffected: number;        
    areaEvacuatedUnits: NfirsAreaEvacuatedUnitsEnum;        
    areaEvacuated: number;
}
export interface INfirsHazmatAreaOutputDto extends IUpdatableOutputModelBaseDto {        
    isReleasedBelowGrade: boolean;        
    isReleasedInsideStructure: boolean;        
    storyReleasedOn: number;        
    isReleasedOutStructure: boolean;        
    populationDensity: NfirsPopulationDensityEnum;        
    nfirsHazmatActionTakenEntry1Id: number;        
    nfirsHazmatActionTakenEntry2Id: number;        
    nfirsHazmatActionTakenEntry3Id: number;        
    numberPeopleEvacuated: number;        
    numberBuildingEvacuated: number;        
    nfirsIgnitionOrReleaseEntryId: number;        
    areaAffectedUnits: NfirsAreaAffectedUnitsEnum;        
    areaAffected: number;        
    areaEvacuatedUnits: NfirsAreaEvacuatedUnitsEnum;        
    areaEvacuated: number;
}
 

export enum NfirsPopulationDensityEnum {
    Urban = 1,
    Suburban = 2,
    Rural = 3
}
export enum NfirsAreaAffectedUnitsEnum {
    SquareFeet = 1,
    Blocks = 2,
    SquareMiles = 3
}
export enum NfirsAreaEvacuatedUnitsEnum {
    SquareFeet = 1,
    Blocks = 2,
    SquareMiles = 3,
    None = 4
}export interface INfirsHazmatCauseInputDto extends IUpdatableInputModelBaseDto {        
    reportId: number;        
    nfirsHazmatCauseOfReleaseEntryId: number;        
    nfirsFactorsContributingToReleaseEntry1Id: number;        
    nfirsFactorsContributingToReleaseEntry2Id: number;        
    nfirsFactorsContributingToReleaseEntry3Id: number;        
    nfirsFactorsContributingToMitigationEntry1Id: number;        
    nfirsFactorsContributingToMitigationEntry2Id: number;        
    nfirsFactorsContributingToMitigationEntry3Id: number;        
    hazmatDisposition: NfirsHazmatDispositionEnum;        
    numCivilianInjuries: number;        
    numCivilianDeaths: number;
}
export interface INfirsHazmatCauseOutputDto extends IUpdatableOutputModelBaseDto {        
    nfirsHazmatCauseOfReleaseEntryId: number;        
    nfirsFactorsContributingToReleaseEntry1Id: number;        
    nfirsFactorsContributingToReleaseEntry2Id: number;        
    nfirsFactorsContributingToReleaseEntry3Id: number;        
    nfirsFactorsContributingToMitigationEntry1Id: number;        
    nfirsFactorsContributingToMitigationEntry2Id: number;        
    nfirsFactorsContributingToMitigationEntry3Id: number;        
    hazmatDisposition: NfirsHazmatDispositionEnum;        
    numCivilianInjuries: number;        
    numCivilianDeaths: number;
}
 

export enum NfirsHazmatDispositionEnum {
    CompletedByFireServiceOnly = 1,
    CompletedWithFireServicePresent = 2,
    ReleasedToLocalAgency = 3,
    ReleasedToCountyAgency = 4,
    ReleasedToStateAgency = 5,
    ReleasedToFederalAgency = 6,
    ReleasedToPrivateAgency = 7,
    ReleasedToPropertyOwnerOrManager = 8
}export interface INfirsHazmatChemicalInputDto extends IUpdatableInputModelBaseDto {        
    reportId: number;        
    unNumber: string;        
    nfirsHazmatDotClassificationEntryId: number;        
    casRegistrationNumber: string;        
    name: string;        
    nfirsChemicalContainerTypeEntryId: number;        
    estimatedContainerCapacityUnitsEntryId: number;        
    estimatedContainerCapacity: number;        
    estimatedAmountReleasedUnitsId: number;        
    estimatedAmountReleased: number;        
    nfirsPhysicalStateEntryId: number;        
    nfirsReleasedInto: NfirsReleasedIntoEnum;
}
export interface INfirsHazmatChemicalOutputDto extends IUpdatableOutputModelBaseDto {        
    unNumber: string;        
    nfirsHazmatDotClassificationEntryId: number;        
    casRegistrationNumber: string;        
    name: string;        
    nfirsChemicalContainerTypeEntryId: number;        
    estimatedContainerCapacityUnitsEntryId: number;        
    estimatedContainerCapacity: number;        
    estimatedAmountReleasedUnitsId: number;        
    estimatedAmountReleased: number;        
    nfirsPhysicalStateEntryId: number;        
    nfirsReleasedInto: NfirsReleasedIntoEnum;
}
 

export enum NfirsHazmatDotClassificationSectionEnum {
    Class1Explosives = 1,
    Class2Gases = 2,
    Class3FlammableLiquids = 3,
    Class4FlammableSolids = 4,
    Class5OxidizersAndOrganicPeroxides = 5,
    Class6ToxicMaterials = 6,
    Class7RadioactiveMaterials = 7,
    Class8CorrosiveMaterials = 8,
    Class9MiscellaneousDangerousGoofs = 9
}
export enum NfirsUnitTypeSectionEnum {
    Volume = 1,
    Weight = 2
}
export enum NfirsReleasedIntoEnum {
    Air = 1,
    Water = 2,
    Ground = 3,
    WaterAndGround = 4,
    AirAndGround = 5,
    WaterAndAir = 6,
    AirWaterAndGround = 7,
    ConfinedNoEnvironmentalImpact = 8
}export interface INfirsHazmatEquipmentInvolvedInputDto extends IUpdatableInputModelBaseDto {        
    reportId: number;        
    isEquipmentInvolved: boolean;        
    nfirsEquipmentId: number;        
    brand: string;        
    serialNumber: string;        
    year: string;        
    model: string;        
    nfirsEquipmentTypeEntryId: number;
}
export interface INfirsHazmatEquipmentInvolvedOutputDto extends IUpdatableOutputModelBaseDto {        
    isEquipmentInvolved: boolean;        
    nfirsEquipmentId: number;        
    brand: string;        
    serialNumber: string;        
    year: string;        
    model: string;        
    nfirsEquipmentTypeEntryId: number;
}export interface INfirsHazmatMobilePropertyInputDto extends IUpdatableInputModelBaseDto {        
    reportId: number;        
    isMobilePropertyInvolved: boolean;        
    nfirsMobilePropertyTypeEntryId: number;        
    nfirsMobilePropertyMakeEntryId: number;        
    model: string;        
    year: string;        
    stateId: number;        
    licencePlateNumber: string;        
    dotNumber: string;
}
export interface INfirsHazmatMobilePropertyOutputDto extends IUpdatableOutputModelBaseDto {        
    isMobilePropertyInvolved: boolean;        
    nfirsMobilePropertyTypeEntryId: number;        
    nfirsMobilePropertyMakeEntryId: number;        
    model: string;        
    year: string;        
    stateId: number;        
    licencePlateNumber: string;        
    dotNumber: string;
}export interface INfirsPersonnelInputDto extends IUpdatableInputModelBaseDto {        
    reportId: number;        
    personnelEmpId: string;        
    rank: string;        
    attend: boolean;        
    nfirsActionTakenId: number;
}
export interface INfirsPersonnelOutputDto extends IUpdatableOutputModelBaseDto {        
    personnelEmpId: string;        
    firstName: string;        
    lastName: string;        
    rank: string;        
    attend: boolean;        
    nfirsActionTakenId: number;        
    nfirsActionTakenExternalId: string;
}
export interface INfirsPersonnelGridOutputDto extends IGridOutputBaseDto {        
    nfirsPersonnelOutputDtos: INfirsPersonnelOutputDto[];
}export interface INfirsRemarkCreateDto extends IUpdatableInputModelBaseDto {        
    reportId: number;        
    remarks: string;        
    remarkSection: NfirsRemarkSectionEnum;
}
export interface INfirsRemarkUpdateDto extends IUpdatableInputModelBaseDto {        
    remarks: string;        
    fileName: string;        
    isDeleteFile: boolean;        
    reportId: number;
}
export interface INfirsRemarkOutputDto extends IUpdatableOutputModelBaseDto {        
    name: string;        
    userId: number;        
    remarks: string;        
    datePublished: Date;        
    fileName: string;
}
 

export enum NfirsRemarkSectionEnum {
    Basic = 1,
    Arson = 2
}export interface INfirsCalStatReportExportInputDto {        
    year: number;        
    month: number;
}
export interface INfirsCatBatchReportGridItemsDto {        
    id: number;        
    year: number;        
    month: number;        
    requestedOn: Date;        
    processStarted: Date;        
    processedOn: Date;        
    errorMessage: string;
}
export interface INfirsReportSearchInputDto {        
    isMyReportsOnly: boolean;        
    isOpenOnly: boolean;        
    status: NfirsReportStatusEnum;        
    startDateString: string;        
    endDateString: string;        
    moduleId: string;        
    tabId: string;        
    columnId: string;        
    query: string;
}


export interface INfirsReportOutputDto extends IUpdatableOutputModelBaseDto {        
    number: string;        
    eventNumber: string;        
    date: Date;        
    location: string;        
    incidentType: string;        
    officerInCharge: string;        
    isOpen: boolean;        
    isArsonReport: boolean;        
    isWildlandEnabled: boolean;        
    crossStreetDirection: string;        
    fireDepartmentId: string;        
    stateAbbreviation: string;        
    exposure: number;        
    isModuleRequiredHelper: IIsModuleRequiredOutputDto;        
    parentCaseNumber: string;        
    isComplete: boolean;
}
export interface INfirsReportLockInputDto extends IUpdatableInputModelBaseDto {        
    reportId: number;        
    action: NfirsLoggingActionEnum;
}
export interface INfirsCatBatchReportGridDto extends IGridOutputBaseDto {        
    nfirsCatBatchReportGridItemsDtos: INfirsCatBatchReportGridItemsDto[];
}
export interface INfirsReportGridOutputDto extends IGridOutputBaseDto {        
    nfirsReportOutputDtos: INfirsReportOutputDto[];
}
 

export enum NfirsReportStatusEnum {
    Any = 0,
    Open = 1,
    Locked = 2
}export interface INfirsSearchFieldsModuleDto {        
    id: string;        
    name: string;
}
export interface INfirsSearchFieldsTabDto {        
    id: string;        
    name: string;
}
export interface INfirsSearchFieldsColumnDto {        
    id: string;        
    name: string;        
    propertyType: string;
}


 

export enum NfirsSearchAttributeCollectionTypeEnum {
    Simple = 1,
    Complex = 2
}export interface INfirsStructureFireAesInputDto extends IUpdatableOutputModelBaseDto {        
    reportId: number;        
    nfirsStructureFireAesPresenceEntryId: number;        
    nfirsStructureFireAesTypeEntryId: number;        
    nfirsStructureFireAesOperationEntryId: number;        
    numSprinklers: number;        
    nfirsStructureFireAesFailureReasonEntryId: number;
}
export interface INfirsStructureFireAesOutputDto extends IUpdatableOutputModelBaseDto {        
    nfirsStructureFireAesPresenceEntryId: number;        
    nfirsStructureFireAesTypeEntryId: number;        
    nfirsStructureFireAesOperationEntryId: number;        
    numSprinklers: number;        
    nfirsStructureFireAesFailureReasonEntryId: number;
}export interface INfirsStructureFireDamageInputDto extends IUpdatableOutputModelBaseDto {        
    reportId: number;        
    storyOfFireOrigin: number;        
    isBelowGrade: boolean;        
    nfirsStructureFireFireSpreadEnum: NfirsStructureFireFireSpreadEnum;        
    noStoriesWithMinorDamage: number;        
    noStoriesWithSignificantDamage: number;        
    noStoriesWithHeavyDamage: number;        
    noStoriesWithExtremeDamage: number;
}
export interface INfirsStructureFireDamageOutputDto extends IUpdatableOutputModelBaseDto {        
    storyOfFireOrigin: number;        
    isBelowGrade: boolean;        
    nfirsStructureFireFireSpreadEnum: NfirsStructureFireFireSpreadEnum;        
    noStoriesWithMinorDamage: number;        
    noStoriesWithSignificantDamage: number;        
    noStoriesWithHeavyDamage: number;        
    noStoriesWithExtremeDamage: number;
}
 

export enum NfirsStructureFireFireSpreadEnum {
    ConfinedToRoomOfOrigin = 2,
    ConfinedToFloorOfOrigin = 3,
    ConfinedToBuildingOfOrigin = 4,
    BeyondBuildingOfOrigin = 5
}
export enum NfirsTypeOfMaterialContributingToFlameSpreadEntryEnum {
    FlammableGas = 1,
    FlammableOrContributingLiquid = 2,
    VolatileSolidOrChemical = 3,
    Plastics = 4,
    NaturalProduct = 5,
    WoodOrPaperProcessed = 6,
    FabricTextilesFur = 7,
    MaterialCompoundWithOil = 8,
    Other = 99
}export interface INfirsStructureFireDetectorInputDto extends IUpdatableOutputModelBaseDto {        
    reportId: number;        
    nfirsStructureFireDetectorPresenceEntryId: number;        
    nfirsStructureFireDetectorTypeEntryId: number;        
    nfirsStructureFireDetectorPowerSupplyEntryId: number;        
    nfirsStructureFireDetectorOperationEntryId: number;        
    nfirsStructureFireDetectorEffectivenessEntryId: number;        
    nfirsStructureFireDetectorFailureReasonEntryId: number;
}
export interface INfirsStructureFireDetectorOutputDto extends IUpdatableOutputModelBaseDto {        
    nfirsStructureFireDetectorPresenceEntryId: number;        
    nfirsStructureFireDetectorTypeEntryId: number;        
    nfirsStructureFireDetectorPowerSupplyEntryId: number;        
    nfirsStructureFireDetectorOperationEntryId: number;        
    nfirsStructureFireDetectorEffectivenessEntryId: number;        
    nfirsStructureFireDetectorFailureReasonEntryId: number;
}export interface INfirsStructureFireStructureTypeInputDto extends IUpdatableOutputModelBaseDto {        
    reportId: number;        
    nfirsStructureTypeEntryId: number;        
    nfirsStructureBuildingStatusTypeEntryId: number;        
    storiesAboveGrade: number;        
    storiesBelowGrade: number;        
    length: number;        
    width: number;        
    nfirsTypeOfMaterialContributingToFlameSpreadEntryId: number;        
    nfirsItemFirstIgnitedEntryId: number;
}
export interface INfirsStructureFireStructureTypeOutputDto extends IUpdatableOutputModelBaseDto {        
    nfirsStructureTypeEntryId: number;        
    nfirsStructureBuildingStatusTypeEntryId: number;        
    storiesAboveGrade: number;        
    storiesBelowGrade: number;        
    totalSquareFeet: number;        
    length: number;        
    width: number;        
    nfirsTypeOfMaterialContributingToFlameSpreadEntryId: number;        
    nfirsItemFirstIgnitedEntryId: number;
}export enum NfirsTestEnum {
    MyFirstOne = 0,
    MySecondOne = 1
}export interface INfirsValidationOutputDto {        
    valid: boolean;        
    reportConcurrencyToken: string;        
    nfirsVerificationModuleOutputDtos: INfirsValidationModuleOutputDto[];
}
export interface INfirsValidationModuleOutputDto {        
    valid: boolean;        
    nfirsValidationModulesEnum: NfirsValidationModulesEnum;        
    nfirsVerificationTabOutputDtos: INfirsVerificationTabOutputDto[];
}
export interface INfirsVerificationTabOutputDto {        
    valid: boolean;        
    nfirsValidationTabsEnum: NfirsValidationTabsEnum;        
    itemId: number;        
    httpErrorOutputDto: IHttpErrorOutputDto;
}
export interface IIsModuleRequiredOutputDto {        
    doesFireModuleAppear: boolean;        
    isFireModuleRequired: boolean;        
    doesStructureFireModuleAppear: boolean;        
    isStructureFireModuleRequired: boolean;        
    doesArsonModuleAppear: boolean;        
    doesHazmatModuleAppear: boolean;        
    doesWildlandModuleAppear: boolean;        
    isBasicOnly: boolean;
}


 

export enum NfirsValidationModulesEnum {
    Basic = 0,
    ApparatusPersonnel = 1,
    Fire = 2,
    StructureFire = 3,
    Hazmat = 4,
    Arson = 5,
    Wildland = 6
}
export enum NfirsValidationTabsEnum {
    BasicAuthority = 0,
    BasicIncident = 1,
    BasicLocation = 2,
    BasicDates = 3,
    BasicActions = 4,
    BasicProperty = 5,
    BasicInvolved = 6,
    BasicRemarks = 7,
    ApparatusApparatus = 8,
    ApparatusPersonnel = 9,
    FireProperty = 10,
    FireIgnition = 11,
    FireEquipmentInvolved = 12,
    FireMobileProperty = 13,
    StructureFireStructureType = 14,
    StructureFireDamage = 15,
    StructureFireDetector = 16,
    StructureFireAes = 17,
    HazmatArea = 18,
    HazmatCause = 19,
    HazmatChemical = 20,
    HazmatMobileProperty = 21,
    HazmatEquipmentInvolved = 22,
    ArsonInvestigation = 23,
    ArsonDetails = 24,
    ArsonAgencyReferral = 25,
    ArsonJuvenile = 26,
    ArsonRemarks = 27,
    WildlandLocation = 28,
    WildlandIgnition = 29,
    WildlandProperty = 30,
    WildlandPeopleInvolved = 31,
    WildlandDetail = 32
}export interface INfirsWildlandDetailInputDto extends IUpdatableInputModelBaseDto {        
    reportId: number;        
    distanceFromRightOfWay: number;        
    nfirsTypeOfRightOfWayEntryId: number;        
    elevation: number;        
    nfirsPositionOnSlopeEntryId: number;        
    nfirsAspectEntryId: number;        
    flameLength: number;        
    rateOfSpeed: number;
}
export interface INfirsWildlandDetailOutputDto extends IUpdatableOutputModelBaseDto {        
    distanceFromRightOfWay: number;        
    nfirsTypeOfRightOfWayEntryId: number;        
    elevation: number;        
    nfirsPositionOnSlopeEntryId: number;        
    nfirsAspectEntryId: number;        
    flameLength: number;        
    rateOfSpeed: number;
}export interface INfirsWildlandIgnitionInputDto extends IUpdatableInputModelBaseDto {        
    reportId: number;        
    nfirsWildlandFireCauseEntryId: number;        
    nfirsHumanFactorsEntryId: number;        
    nfirsContributingFactorEntry1Id: number;        
    nfirsContributingFactorEntry2Id: number;        
    nfirsFireSuppressionEntry1Id: number;        
    nfirsFireSuppressionEntry2Id: number;        
    nfirsFireSuppressionEntry3Id: number;        
    nfirsHeatSourceEntryId: number;
}
export interface INfirsWildlandIgnitionOutputDto extends IUpdatableOutputModelBaseDto {        
    nfirsWildlandFireCauseEntryId: number;        
    nfirsHumanFactorsEntryId: number;        
    nfirsContributingFactorEntry1Id: number;        
    nfirsContributingFactorEntry2Id: number;        
    nfirsFireSuppressionEntry1Id: number;        
    nfirsFireSuppressionEntry2Id: number;        
    nfirsFireSuppressionEntry3Id: number;        
    nfirsHeatSourceEntryId: number;
}export interface INfirsWildlandLocationInputDto extends IUpdatableInputModelBaseDto {        
    reportId: number;        
    latitude: number;        
    longitude: number;        
    township: number;        
    isTownshipNorth: boolean;        
    range: number;        
    isRangeEast: boolean;        
    section: string;        
    nfirsLocationSubsectionEntryId: number;        
    nfirsMeridianEntryId: number;        
    nfirsAreaTypeEntryId: number;
}
export interface INfirsWildlandLocationOutputDto extends IUpdatableOutputModelBaseDto {        
    reportId: number;        
    latitude: number;        
    longitude: number;        
    township: number;        
    isTownshipNorth: boolean;        
    range: number;        
    isRangeEast: boolean;        
    section: string;        
    nfirsLocationSubsectionEntryId: number;        
    nfirsMeridianEntryId: number;        
    nfirsAreaTypeEntryId: number;
}export interface INfirsWildlandPeopleInvolvedInputDto extends IUpdatableInputModelBaseDto {        
    reportId: number;        
    undetermined: number;        
    nfirsPrivateTypeEntryId: number;        
    privatePercentage: number;        
    nfirsPublicTypeEntryId: number;        
    publicPercentage: number;        
    wildlandFederalAgencyCode: string;        
    nfirsFuelModelEntryId: number;        
    nfirsPersonResponsibleForFireEntryId: number;        
    maleFemaleEnum: NfirsJuvenileMaleFemaleEnum;        
    age: number;        
    dateOfBirth: Date;        
    nfirsActivityOfPersonInvolvedEntryId: number;
}
export interface INfirsWildlandPeopleInvolvedOutputDto extends IUpdatableOutputModelBaseDto {        
    undetermined: number;        
    nfirsPrivateTypeEntryId: number;        
    privatePercentage: number;        
    nfirsPublicTypeEntryId: number;        
    publicPercentage: number;        
    wildlandFederalAgencyCode: string;        
    nfirsFuelModelEntryId: number;        
    nfirsPersonResponsibleForFireEntryId: number;        
    maleFemaleEnum: NfirsJuvenileMaleFemaleEnum;        
    age: number;        
    dateOfBirth: Date;        
    nfirsActivityOfPersonInvolvedEntryId: number;
}export interface INfirsWildlandPropertyInputDto extends IUpdatableInputModelBaseDto {        
    reportId: number;        
    nfirsMobilePropertyTypeEntryId: number;        
    nfirsEquipmentTypeEntryId: number;        
    nfirsWeatherTypeEntryId: number;        
    nfirsWindDirectionEntryId: number;        
    windSpeed: number;        
    airTemperature: number;        
    relativeHumidity: number;        
    fuelMoisture: number;        
    nfirsFireDangerRatingEntryId: number;        
    numBuildingIgnited: number;        
    numBuildingsThreatened: number;        
    totalAcresBurned: number;        
    primaryCropsBurned1: string;        
    primaryCropsBurned2: string;        
    primaryCropsBurned3: string;
}
export interface INfirsWildlandPropertyOutputDto extends IUpdatableOutputModelBaseDto {        
    nfirsMobilePropertyTypeEntryId: number;        
    nfirsEquipmentTypeEntryId: number;        
    nfirsWeatherTypeEntryId: number;        
    nfirsWindDirectionEntryId: number;        
    windSpeed: number;        
    airTemperature: number;        
    relativeHumidity: number;        
    fuelMoisture: number;        
    nfirsFireDangerRatingEntryId: number;        
    numBuildingIgnited: number;        
    numBuildingsThreatened: number;        
    totalAcresBurned: number;        
    primaryCropsBurned1: string;        
    primaryCropsBurned2: string;        
    primaryCropsBurned3: string;
}export interface IPersonnelOutputDto {        
    userId: number;        
    firstName: string;        
    lastName: string;        
    email: string;        
    userName: string;        
    phoneNumber: string;        
    emergencyNotification: string;        
    isActive: boolean;        
    isDepartmentSupervisor: boolean;        
    lastLoggedIn: Date;        
    lastModified: Date;        
    lastEmailed: Date;        
    personnelSettingOutputDto: IPersonnelSettingOutputDto;        
    personnelRoleOutputDtos: IPersonnelRoleOutputDto[];        
    personnelAssignedSupervisorDtos: IPersonnelAssignedSupervisorOutputDto[];        
    rankOutputDtos: IRankOutputDto[];        
    assignees: IPersonnelAssigneeOutputDto[];
}
export interface IPersonnelUserInfoOutputDto {        
    userId: number;        
    firstName: string;        
    lastName: string;        
    email: string;        
    userName: string;        
    phoneNumber: string;        
    emergencyNotification: string;        
    lastLoggedIn: Date;        
    lastModified: Date;        
    rankTitle: string;        
    personnelId: string;
}
export interface IPersonnelAssignedSupervisorOutputDto {        
    assignedSupervisorId: number;        
    active: boolean;        
    firstName: string;        
    lastName: string;
}
export interface IPersonnelAssigneeOutputDto {        
    assigneeId: number;        
    active: boolean;        
    firstName: string;        
    lastName: string;
}
export interface IRankOutputDto {        
    rankId: number;        
    title: string;        
    selected: boolean;
}
export interface IPersonnelGridItemOutputDto {        
    userId: number;        
    firstName: string;        
    lastName: string;        
    email: string;        
    phoneNumber: string;        
    emergencyNotification: string;        
    isActive: boolean;
}
export interface IPersonnelInputDto {        
    userId: number;        
    firstName: string;        
    lastName: string;        
    email: string;        
    phoneNumber: string;        
    isActive: boolean;        
    isDepartmentSupervisor: boolean;        
    rankInputDto: IRankInputDto;        
    personnelRoleDtos: IPersonnelRoleInputDto[];        
    personnelSettingInputDto: IPersonnelSettingInputDto;        
    personnelAssignedSupervisorDtos: IPersonnelAssignedSupervisorInputDto[];        
    assignees: IPersonnelAssigneeInputDto[];
}
export interface IPersonnelAssignedSupervisorInputDto {        
    assignedSupervisorId: number;
}
export interface IRankInputDto {        
    rankId: number;
}
export interface IPersonnelInputOutputDto {        
    userId: number;        
    firstName: string;        
    lastName: string;        
    email: string;        
    phoneNumber: string;        
    group: string;        
    emergencyNotification: string;        
    isActive: boolean;
}


export interface IPersonnelSettingOutputDto extends IUpdatableOutputModelBaseDto {        
    jacId: string;        
    personnelId: string;
}
export interface IPersonnelAssigneeInputDto extends IPersonnelAssigneeOutputDto {
}
export interface IPersonnelSettingInputDto extends IUpdatableInputModelBaseDto {        
    jacId: string;        
    personnelId: string;
}
export interface IPersonnelGridOutputDto extends IGridOutputBaseDto {        
    personnelGridItemOutputDtos: IPersonnelGridItemOutputDto[];
}export interface IScheduleOutputDto extends IUpdatableOutputModelBaseDto {        
    initialStartDate: Date;        
    shifts: IShiftOutputDto[];
}
export interface IScheduleInputDto extends IUpdatableInputModelBaseDto {        
    departmentId: number;        
    initialStartDate: string;        
    shifts: IShiftInputDto[];
}export interface ISerilogGridItemDto {        
    id: number;        
    message: string;        
    level: string;        
    timeStamp: Date;        
    applicationName: string;        
    userId: string;
}
export interface ISerilogItemDto {        
    id: number;        
    message: string;        
    level: string;        
    timeStamp: Date;        
    applicationName: string;        
    userId: string;        
    exception: string;        
    logEvent: string;
}


export interface ISerilogGridDto extends IGridOutputBaseDto {        
    serilogGridItemDtos: ISerilogGridItemDto[];
}export interface IShiftOutputDto extends IUpdatableOutputModelBaseDto {        
    name: string;        
    duration: number;        
    color: string;
}
export interface IShiftInputDto extends IUpdatableInputModelBaseDto {        
    name: string;        
    duration: number;        
    color: string;
}export interface ITimeZoneDto extends IUpdatableOutputModelBaseDto {        
    name: string;        
    abbreviation: string;
}export interface ITrainingReportColumnsDto {        
    isScheduleReportAvailable: boolean;        
    trainingReportGroupDtos: ITrainingReportGroupDto[];
}
export interface ITrainingReportGroupDto {        
    groupName: TrainingReportGroupNameEnum;        
    trainingReportColumnsDetailsDtos: ITrainingReportColumnsDetailsDto[];
}
export interface ITrainingReportColumnsDetailsDto {        
    id: string;        
    columLabel: string;        
    showColumn: TrainingReportShowColumnEnum;
}
export interface ITrainingReportBaseDto {        
    reportName: string;        
    reportDescription: string;        
    trainingReportScheduleDto: ITrainingReportScheduleDto;        
    trainingReportSettingsDto: ITrainingReportSettingsDto;
}
export interface ITrainingReportCoursesDrillsOutputDto {        
    id: number;        
    name: string;        
    lastName: string;
}
export interface ITrainingReportGridItemDto {        
    id: number;        
    reportName: string;        
    owner: string;        
    reportType: string;        
    dateUpdated: Date;        
    dateCreated: Date;        
    nextTimeScheduleRun: Date;        
    reportScheduleEnum: string;
}
export interface ITrainingReportExtractGridDto {        
    lastDrillId: number;        
    lastCourseId: number;        
    trainingReportExtractDetailDtos: ITrainingReportExtractDetailDto[];
}
export interface ITrainingReportExtractDetailDto {        
    recordType: string;        
    firstName: string;        
    lastName: string;        
    departmentName: string;        
    groups: string;        
    rank: string;        
    employeeId: string;        
    supervisorFirstName: string;        
    supervisorLastName: string;        
    email: string;        
    userJacId: string;        
    hours: number;        
    location: string;        
    instructor: string;        
    agencyJacId: string;        
    status: string;        
    jacCode: string;        
    drillReportEligibility: string;        
    lastModifiedOn: Date;        
    lastModifiedByFirstName: string;        
    lastModifiedByLastName: string;        
    trainingType: string;        
    drillId: number;        
    drillName: string;        
    drillStartDate: Date;        
    drillEndDate: Date;        
    drillStartTime: string;        
    drillEndTime: string;        
    calJac: string;        
    isaCourse: string;        
    drillSubject: string;        
    drillAreaOfStudy: string;        
    calJacTrainingType: string;        
    company: string;        
    reporterFirstName: string;        
    reporterLastName: string;        
    drillDescription: string;        
    shift: string;        
    isaDetailsOfCourse: string;        
    courseId: number;        
    courseName: string;        
    courseNumber: string;        
    categoryName: string;        
    courseCharacteristics: string;        
    takenFromDate: Date;        
    takenToDate: Date;        
    providerNumber: string;        
    courseLibrary: string;        
    courseDescription: string;        
    courseObjective: string;        
    isTaken: string;        
    hasAttachment: string;
}
export interface ITrainingReportExtractDetailDto {        
    recordType: string;        
    firstName: string;        
    lastName: string;        
    departmentName: string;        
    groups: string;        
    rank: string;        
    employeeId: string;        
    supervisorFirstName: string;        
    supervisorLastName: string;        
    email: string;        
    userJacId: string;        
    hours: number;        
    location: string;        
    instructor: string;        
    agencyJacId: string;        
    status: string;        
    jacCode: string;        
    drillReportEligibility: string;        
    lastModifiedOn: Date;        
    lastModifiedByFirstName: string;        
    lastModifiedByLastName: string;        
    trainingType: string;        
    drillId: number;        
    drillName: string;        
    drillStartDate: Date;        
    drillEndDate: Date;        
    drillStartTime: string;        
    drillEndTime: string;        
    calJac: string;        
    isaCourse: string;        
    drillSubject: string;        
    drillAreaOfStudy: string;        
    calJacTrainingType: string;        
    company: string;        
    reporterFirstName: string;        
    reporterLastName: string;        
    drillDescription: string;        
    shift: string;        
    isaDetailsOfCourse: string;        
    courseId: number;        
    courseName: string;        
    courseNumber: string;        
    categoryName: string;        
    courseCharacteristics: string;        
    takenFromDate: Date;        
    takenToDate: Date;        
    providerNumber: string;        
    courseLibrary: string;        
    courseDescription: string;        
    courseObjective: string;        
    isTaken: string;        
    hasAttachment: string;
}
export interface ITrainingReportExtractDetailDto {        
    recordType: string;        
    firstName: string;        
    lastName: string;        
    departmentName: string;        
    groups: string;        
    rank: string;        
    employeeId: string;        
    supervisorFirstName: string;        
    supervisorLastName: string;        
    email: string;        
    userJacId: string;        
    hours: number;        
    location: string;        
    instructor: string;        
    agencyJacId: string;        
    status: string;        
    jacCode: string;        
    drillReportEligibility: string;        
    lastModifiedOn: Date;        
    lastModifiedByFirstName: string;        
    lastModifiedByLastName: string;        
    trainingType: string;        
    drillId: number;        
    drillName: string;        
    drillStartDate: Date;        
    drillEndDate: Date;        
    drillStartTime: string;        
    drillEndTime: string;        
    calJac: string;        
    isaCourse: string;        
    drillSubject: string;        
    drillAreaOfStudy: string;        
    calJacTrainingType: string;        
    company: string;        
    reporterFirstName: string;        
    reporterLastName: string;        
    drillDescription: string;        
    shift: string;        
    isaDetailsOfCourse: string;        
    courseId: number;        
    courseName: string;        
    courseNumber: string;        
    categoryName: string;        
    courseCharacteristics: string;        
    takenFromDate: Date;        
    takenToDate: Date;        
    providerNumber: string;        
    courseLibrary: string;        
    courseDescription: string;        
    courseObjective: string;        
    isTaken: string;        
    hasAttachment: string;
}
export interface ITrainingReportSettingsBaseDto {        
    fromDate: string;        
    toDate: string;        
    typeCourses: boolean;        
    typeDrills: boolean;        
    typeOnlineTraining: boolean;        
    drillNameIds: number[];        
    courseIds: number[];        
    userIds: number[];        
    columnIds: string[];
}
export interface ITrainingReportScheduleDto {        
    reportScheduleEnum: TrainingReportScheduleEnum;        
    weeklyRepeatOnEnum: TrainingReportWeeklyRepeatOnEnum;        
    monthlyRepeatOnLastDay: boolean;        
    monthlyRepeatOnDay: number;        
    sendToReportOwner: boolean;        
    sendToEmailAddresses: string;
}


export interface ITrainingReportInputDto extends ITrainingReportBaseDto {
}
export interface ITrainingReportOutputDto extends ITrainingReportBaseDto {        
    id: number;        
    nextTimeScheduleRun: Date;        
    coursesInReport: ITrainingReportCoursesDrillsOutputDto[];        
    drillsInReport: ITrainingReportCoursesDrillsOutputDto[];        
    usersInReport: ITrainingReportCoursesDrillsOutputDto[];
}
export interface ITrainingReportGridDto extends IGridOutputBaseDto {        
    isScheduleReportAvailable: boolean;        
    trainingReportGridItemDtos: ITrainingReportGridItemDto[];
}
export interface ITrainingReportSettingsDto extends ITrainingReportSettingsBaseDto {        
    agGridSettings: string;
}
export interface ITrainingReportExtractSettingsDto extends ITrainingReportSettingsBaseDto {        
    lastDrillId: number;        
    lastCourseId: number;
}
 

export enum TrainingReportGroupNameEnum {
    General = 0,
    Drills = 1,
    Courses = 2
}
export enum TrainingReportShowColumnEnum {
    Yes = 0,
    No = 1
}
export enum TrainingReportScheduleEnum {
    None = 0,
    Daily = 1,
    Weekly = 2,
    Monthly = 3
}
export enum TrainingReportWeeklyRepeatOnEnum {
    Monday = 1,
    Tuesday = 2,
    Wednesday = 3,
    Thursday = 4,
    Friday = 5,
    Saturday = 6,
    Sunday = 0
}export interface ITrainingTypeDrillTemplateDto {        
    id: number;        
    isEnabled: boolean;        
    departmentId: number;
}


export interface ITrainingTypeInputDto extends IUpdatableInputModelBaseDto {        
    name: string;        
    departmentId: number;        
    isEnabled: boolean;
}
export interface ITrainingTypeOutputDto extends IUpdatableOutputModelBaseDto {        
    name: string;        
    departmentId: number;        
    isEnabled: boolean;
}export interface IUpdatableOutputModelBaseDto {        
    id: number;        
    dateCreated: Date;        
    dateUpdated: Date;        
    concurrencyToken: string;
}
export interface IUpdatableInputModelBaseDto {        
    id: number;        
    concurrencyToken: string;
}export interface IUserCredSettingsUserOutputDto {        
    name: string;        
    id: number;        
    isSelected: boolean;
}
export interface IUserCredSettingsRankOutputBaseDto {        
    id: number;        
    title: string;
}


export interface IUserCredSettingsInputDto extends IUpdatableInputModelBaseDto {        
    personnelId: string;        
    rankId: number;        
    supervisorIds: number[];        
    isSupervisor: boolean;        
    assigneeIds: number[];        
    isNewDriver: boolean;
}
export interface IUserCredSettingsOutputDto extends IUpdatableOutputModelBaseDto {        
    personnelId: string;        
    ranks: IUserCredSettingsRankOutputDto[];        
    supervisors: IUserCredSettingsUserOutputDto[];        
    isSupervisor: boolean;        
    assignees: IUserCredSettingsUserOutputDto[];        
    isNewDriver: boolean;
}
export interface IUserCredSettingsRankOutputDto extends IUserCredSettingsRankOutputBaseDto {        
    isSelected: boolean;
}export interface IUserDepartmentRelationDto {        
    userId: number;        
    departmentId: number;
}export interface IUserLogDrillEligibilityGridOutputItemDto {        
    id: number;        
    startTime: Date;        
    endTime: Date;        
    eligibilityType: UserLogDrillEligibilityEnum;
}


export interface IUserLogDrillEligibilityInputDto extends IUpdatableInputModelBaseDto {        
    userId: number;        
    startTimeString: string;        
    endTimeString: string;        
    eligibilityType: UserLogDrillEligibilityEnum;
}
export interface IUserLogDrillEligibilityOutputDto extends IUpdatableOutputModelBaseDto {        
    startTime: Date;        
    endTime: Date;        
    startTimeString: string;        
    endTimeString: string;        
    eligibilityType: UserLogDrillEligibilityEnum;        
    userId: number;
}
export interface IUserLogDrillEligibilityGridDto extends IGridOutputBaseDto {        
    userLogDrillEligibilityGridOutputItemDtos: IUserLogDrillEligibilityGridOutputItemDto[];
}
 

export enum UserLogDrillEligibilityEnum {
    JacEligible = 1,
    IsaEligible = 2
}export interface IPersonnelModuleSecurityDto {        
    oldModulesSecurityDtos: IOldModulesSecurityDto[];        
    userSecurityInputDto: IUserSecurityInputDto;
}
export interface IOldModulesSecurityDto {        
    moduleId: number;        
    isVisible: boolean;        
    isEditable: boolean;        
    removeAccess: boolean;
}
export interface IPersonnelRoleInputDto {        
    roleId: number;        
    active: boolean;
}
export interface IPersonnelRoleOutputDto {        
    roleId: number;        
    active: boolean;        
    name: string;
}
export interface IUserClaimDto {        
    claimType: UserClaimTypeEnum;        
    claimValue: UserClaimValueEnum;        
    userClaimOptions: IUserClaimOptionDto[];
}
export interface IUserClaimOptionDto {        
    userClaimValueOptionId: number;
}
export interface IUserClaimValueOutputDto {        
    userClaimValueEnum: UserClaimValueEnum;        
    description: string;        
    userClaimValueOptions: IUserClaimValueOptionOutputDto[];
}
export interface IUserClaimValueOptionOutputDto {        
    userClaimValueEnum: UserClaimValueEnum;        
    id: number;        
    description: string;
}
export interface IUserClaimTypeOutputDto {        
    userClaimTypeEnum: UserClaimTypeEnum;        
    description: string;
}


export interface IUserSecurityInputDto extends IUpdatableInputModelBaseDto {        
    userId: number;        
    userClaims: IUserClaimDto[];
}
export interface IUserSecurityOutputDto extends IUpdatableOutputModelBaseDto {        
    userId: number;        
    userClaims: IUserClaimDto[];
}
 

export enum UserClaimValueEnum {
    Remove = 0,
    Admin = 1,
    TrainingPortalManager = 2,
    Instructor = 3,
    Learner = 4,
    Staff1 = 5,
    Staff2 = 6,
    LogDrillsManager = 7,
    Reporter1 = 8,
    Reporter2 = 9,
    Self1 = 10,
    Self2 = 11,
    Self3 = 12,
    PersonnelAdmin = 13,
    NfirsAdmin = 14,
    NfirsContributor = 15,
    CredCourseRecordsAdmin = 16,
    CredCourseRecordsManager = 17,
    CredCourseRecordsOwn = 18,
    CredCredentialsAdmin = 19,
    CredCredentialsManager = 20,
    CredCredentialsOwn = 21,
    CredTemplateAdmin = 22,
    CredDashboardManager = 23,
    CredDashboardOwn = 24,
    EsriDashboardView = 25,
    CustomIFrameView = 26,
    IsoTrainingView = 27,
    AssignmentsEdit = 28,
    GroupAdmin = 29,
    CredCredentialsViewOwn = 30,
    NotificationsViewAllSubmitAllEditAllDeleteAll = 31,
    NotificationsViewAll = 32
}
export enum UserClaimValueOptionEnum {
    CETrackersCredDashboardManager = 1,
    ISOTrackerCredDashboardManager = 2,
    CETrackersCredDashboardOwn = 3,
    ISOTrackerCredDashboardOwn = 4,
    DetailReportIsoTrainingView = 5,
    OverwiewReportIsoTrainingView = 6,
    AssignmentsCredDashboardManager = 7,
    AssignmentsCredDashboardOwn = 8,
    DisplayCoursesReport = 9,
    DisplayCredentialsReport = 10,
    DisplayDrillsReport = 11,
    DisplayNfirsReport = 12
}
export enum UserClaimTypeEnum {
    TrainingPortal = 1,
    LogDrills = 2,
    Personnel = 3,
    Nfirs = 4,
    Dashboard = 5,
    CourseRecords = 6,
    Credentials = 7,
    CredTemplates = 8,
    DataVisualization = 9,
    IsoTraining = 10,
    CustomIFrame = 11,
    Assignments = 12,
    Groups = 13,
    Notifications = 14
}