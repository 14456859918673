import React, { useEffect, useState } from "react";
import FormModal from "../Common/FormModal";
import ApsModels from "../../models";
import moment from "moment";
import commonService from "../../services/CommonService";
import { IBasicDialogProps } from "../Common/FormGroups";
import { NfirsFetchStatus, useNfirsGetData } from "../NFIRS/NFIRSHelper";
import ApsServices from "../../services";
import CommonSpinner from "../Common/CommonSpinner";

function SerilogDialog(
  props: IBasicDialogProps<{
    id: number;
  }>
) {
  const [detail, setDetail] = useState<ApsModels.ISerilogItemDto>();

  const logDetail = useNfirsGetData(
    () => ApsServices.http.serilog.get(props.data?.id || 0),
    "Log"
  );

  useEffect(() => {
    logDetail.getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (logDetail.status === NfirsFetchStatus.Complete && logDetail.data) {
      setDetail(logDetail.data);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [logDetail.status]);

  return (
    <FormModal
      isOpen={true}
      title={`Severity Level: ${detail?.level || ""}`}
      close={() => {
        setDetail(undefined);
        props.onClose();
      }}
      submit={(data) => {
        setDetail(undefined);
        props.onClose();
      }}
      primaryButtonOnly={true}
      submitButtonLabel="Close"
      size="xl"
    >
      {logDetail.status === NfirsFetchStatus.InProgress ? (
        <>
          <div className="p-4">
            <CommonSpinner message="Loading..."></CommonSpinner>
          </div>
        </>
      ) : (
        <>
          <div className="flex flex-row">
            <div className="mb-2 me-4">
              <strong>Application Name:</strong> {detail?.applicationName}
            </div>
            <div className="mb-2 me-4">
              <strong>User ID:</strong> {detail?.userId}
            </div>
            <div className="mb-2">
              <strong>Date:</strong>{" "}
              {moment(detail?.timeStamp).format("MMM DD, YYYY hh:mm A")}
            </div>
          </div>
          <strong>Message</strong>
          <textarea
            className="form-control w-100 mb-3"
            readOnly={true}
            style={{ backgroundColor: "#eee" }}
            rows={detail?.message ? 8 : 3}
            value={detail?.message}
          ></textarea>

          {!!detail?.logEvent && (
            <>
              <strong>Exception</strong>
              <div className="text-select-container">
                <textarea
                  className="form-control w-100"
                  readOnly={true}
                  style={{ backgroundColor: "#eee" }}
                  rows={12}
                  value={
                    detail.exception
                    //  JSON.stringify(
                    //     JSON.parse(detail.logEvent),
                    //     undefined,
                    //     2
                    //   )
                  }
                ></textarea>
              </div>
            </>
          )}
        </>
      )}
    </FormModal>
  );
}

export default SerilogDialog;
